import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Application from './Application';
import Availability from './Availability';
import Config from '../config';
import Demo from './Demo';
import GemGlobalStyles from 'theme/gem/styles';
import gemTheme from 'theme/gem';
import Go from './Go';
import SecureRoute from './library/navigation/SecureRoute';
import SelfSchedule from './SelfSchedule';
import Website from './Website';
import { correctPath } from '../libraries/gem';

const App = () => {
  return (
    <ThemeProvider theme={gemTheme}>
      <GemGlobalStyles />
      <Switch>
        <Route component={Availability} path={correctPath('/availability/:id')} />
        <Route component={SelfSchedule} path={correctPath('/schedule/:id')} />
        <SecureRoute component={Application} path={correctPath('/app')} />
        {/* For these routes, if we're logged in already, it will take them to the overview. If not, it will take them
        to the login page that will redirect them back to the overview. */}
        <Route component={() => {
          window.location.href = `${Config.GEM_HOST}/scheduling/app`;
          return null;
        }} path={correctPath('/login')} />
        <Route component={() => {
          window.location.href = `${Config.GEM_HOST}/scheduling/app`;
          return null;
        }} path={correctPath('/signin')} />
        <Route component={() => {
          window.location.href = `${Config.GEM_HOST}/scheduling/app`;
          return null;
        }} path={correctPath('/pilot')} />
        <Route component={Demo} path={correctPath('/demo')} />
        <Route component={Go} path={correctPath('/go/:slug')} />
        {/* The website has InterviewPlanner styles overriding it. */}
        <Route component={Website} path={correctPath('/')} />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
