import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: .95em;
  margin-bottom: .5em;

  &:first-child { margin-top: 0; }
  &:last-child { margin-bottom: 0; }

  .input.checkbox-input {
    margin: 0;
  }

  .input.text-input {
    flex-grow: 1;
    margin: 0;
    margin-left: .5em;
  }
`;

export const StyledExpandingDetailsContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  margin-left: 3.5em;
  width: 100%;

  table {
    width: max-content;

    th {
      background: white;
      border-right: none !important;
      width: 70px;
    }

    tr {
      border: none;

      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
      }
    }
  }
`;

export const StyledEmptyValue = styled.span`
  color: ${({ theme }) => theme.colors.GRAYS.GRAY_500};
`;

export const StyledHelperText = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .8em;
  margin-left: 1em;
  width: 100%;
`;
