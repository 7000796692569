import { capitalize, uniqueId } from 'lodash';
import { useMemo } from 'react';

import Tooltip from '../utils/Tooltip';
import { useSession } from '../../../hooks/use-session';

import type { ATS } from '../../../types';

const interviewSourceLogoUrls: Record<`${ATS}` | 'interviewplanner', string> = {
  gem: '/scheduling/src/assets/images/gem-logo-transparent-bg.svg',
  greenhouse: '/scheduling/src/assets/images/greenhouse-logo-transparent-bg.svg',
  interviewplanner: '/scheduling/src/assets/images/logo-icon.svg',
  lever: '/scheduling/src/assets/images/lever-logo-transparent-bg.svg',
  success_factors: '/scheduling/src/assets/images/success-factors-transparent-bg.svg',
};

interface Props {
  isImported: boolean;
  nonImportedTooltipValue?: string;
}

const ATSImportedIcon = ({
  isImported,
  nonImportedTooltipValue = '',
}: Props) => {
  const { account } = useSession();

  const id = useMemo(() => uniqueId('ats-imported-'), []);

  return (
    <div className="ats-imported-icon-container">
      <img
        alt={capitalize(account?.ats_type)}
        className="ats-imported-icon"
        data-for={id}
        data-tip
        src={interviewSourceLogoUrls[isImported && account?.ats_type ? account.ats_type : 'interviewplanner']}
      />
      {(isImported || nonImportedTooltipValue) && <Tooltip
        id={id}
        position="top"
        value={isImported ? `Imported from ${capitalize(account?.ats_type)}` : nonImportedTooltipValue}
      />}
    </div>
  );
};

export default ATSImportedIcon;
