import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { capitalize, find, keyBy, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import AllowedTimesInput from 'components/library/inputs/AllowedTimesInput';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import DayDurationInput from 'components/library/inputs/DayDurationInput';
import DurationInput from 'components/library/inputs/DurationInput';
import EditorInput from 'components/library/inputs/EditorInput';
import EmailTemplateForm from 'components/library/inputs/EmailTemplateForm';
import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import FeedbackFormSelectInput from 'components/library/inputs/FeedbackFormSelectInput';
import InterviewBatchSelectInput from 'components/library/inputs/InterviewBatchSelectInput';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import SelectInput from 'components/library/inputs/SelectInput';
import TextInput from 'components/library/inputs/TextInput';
import ZoomHostFiltersBuilder from 'components/library/inputs/ZoomHostFiltersBuilder';
import OutboundLink from 'components/library/navigation/OutboundLink';
import ErrorTokenFlash from 'components/library/utils/ErrorTokenFlash';
import Flash from 'components/library/utils/Flash';
import { useInterviewBatches } from 'hooks/queries/interview-batches';
import { useTokens } from 'hooks/queries/tokens';
import { useUsersMap } from 'hooks/queries/users';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSlateEditor } from 'hooks/use-slate-editor';
import { useSession } from 'hooks/use-session';
import { AtsHrefType, constructAtsHref } from 'libraries/candidates';
import { slateValueToHtml } from 'libraries/editor/slate-value-to-html';
import { createEmptyZoomHostFilter } from 'libraries/zoom-hosts';
import pluralize from 'libraries/pluralize';
import { ATS, liveCodingLabels } from 'types';
import { StyledCard, StyledInterviewBatchInputContainer, StyledInterviewDetailsBatchMessage } from './styles';
import { constructInterviewerTemplatesForm, constructSelfSchedulingTokensPayload, defaultValuesForStageInterview, useSelfSchedulingApplications, pathForSelfScheduling } from '../helpers';
import { Step, useNewSelfSchedulingLink } from '../use-new-self-scheduling-link';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { Token, EditableZoomHostFilter, StageInterview } from 'types';
import { correctPath } from 'libraries/gem';
import type { InterviewerTemplate as InterviewerTemplatesFormInterviewerTemplate } from 'components/library/inputs/InterviewerTemplatesForm';
import InterviewerTemplatesForm from 'components/library/inputs/InterviewerTemplatesForm';

const exampleCandidateFacingDetails = '<span>This will be a conversation on company values, operating principles, and your past experiences.</span>';

const PreferencesStep = () => {
  const queryClient = useQueryClient();

  const data = useSelfSchedulingApplications();
  const { isBulk, applicationIDs, applications } = data;
  const arbitraryApplication = data.arbitraryApplication!;
  const stage = arbitraryApplication.current_stage;
  const stageId = arbitraryApplication.current_stage_id;
  const job = arbitraryApplication.job;
  const jobId = arbitraryApplication.job_id;

  const { candidateFacingInterviewDetails } = useLDFlags();

  const { account, currentUser } = useSession();
  const users = useUsersMap({ archived: true });

  const {
    selfSchedulingLink,
    setCompletedStep,
    setSelfSchedulingLink,
  } = useNewSelfSchedulingLink();

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  const [error, setError] = useState<Error | null>(null);
  const [stageInterviewId, setStageInterviewId] = useState(selfSchedulingLink.stage_interview.id || '');
  const [isInterviewBatchSelected, setIsInterviewBatchSelected] = useState(false);
  const [interviewBatchId, setInterviewBatchId] = useState(selfSchedulingLink.interview_batch_id || '');
  const [durationMinutes, setDurationMinutes] = useState(selfSchedulingLink.stage_interview.interview_template.duration_minutes);
  const [businessHours, setBusinessHours] = useState(selfSchedulingLink.schedule_template.business_hours);
  const [allowedTimes, setAllowedTimes] = useState(selfSchedulingLink.schedule_template.allowed_times);
  const [candidateFacingName, setCandidateFacingName] = useState(selfSchedulingLink.stage_interview.interview_template.candidate_facing_name || '');
  const [candidateFacingDetailsSlateEditor, candidateFacingDetailsSlateValue, setCandidateFacingDetailsSlateValue, setCandidateFacingDetails] = useSlateEditor(selfSchedulingLink.stage_interview.interview_template.candidate_facing_details || '');
  const [feedbackFormId, setFeedbackFormId] = useState(selfSchedulingLink.stage_interview.feedback_form_id);
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_advanced_notice_hours);
  const [schedulingIntervalMinutes, setSchedulingIntervalMinutes] = useState(selfSchedulingLink.schedule_template.scheduling_interval_minutes);
  const [isRescheduleEnabled, setIsRescheduleEnabled] = useState(selfSchedulingLink.schedule_template.self_scheduling_reschedule_enabled);
  const [rescheduleNoticeHours, setRescheduleNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_reschedule_notice_hours || 0);
  const [isCancelEnabled, setIsCancelEnabled] = useState(selfSchedulingLink.schedule_template.self_scheduling_cancel_enabled);
  const [cancelNoticeHours, setCancelNoticeHours] = useState(selfSchedulingLink.schedule_template.self_scheduling_cancel_notice_hours || 0);
  const [isFollowUpEnabled, setIsFollowUpEnabled] = useState(Boolean(selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0]));
  const [followUpDelayDays, setFollowUpDelayDays] = useState(selfSchedulingLink.schedule_template.self_scheduling_email_follow_up_templates?.[0].delay_days || 0);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(selfSchedulingLink.schedule_template.video_conferencing_enabled);
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(selfSchedulingLink.schedule_template.zoom_host_filters || createEmptyZoomHostFilter());
  const [isLiveCodingEnabled, setIsLiveCodingEnabled] = useState(selfSchedulingLink.stage_interview.interview_template.live_coding_enabled);
  const [interviewerTemplates, setInterviewerTemplates] = useState<InterviewerTemplatesFormInterviewerTemplate[] | undefined>(
    constructInterviewerTemplatesForm(selfSchedulingLink.stage_interview.interview_template.interviewer_templates)
  );
  const [isEmailEnabled, setIsEmailEnabled] = useState(canSendEmail && Boolean(selfSchedulingLink.schedule_template.self_scheduling_request_email_template));
  const [emailTemplate, setEmailTemplate] = useState(selfSchedulingLink.schedule_template.self_scheduling_request_email_template);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  const stageInterviewsById = useMemo(() => keyBy(stage!.stage_interviews, 'id'), [stage!.stage_interviews]);
  const stageInterview = useMemo<StageInterview | undefined>(() => stageInterviewsById[stageInterviewId], [stageInterviewsById, stageInterviewId]);
  // We can't wrap this in a useMemo since candidateFacingDetailsSlateValue doesn't change its value as contents are updated.
  const candidateFacingDetails = slateValueToHtml(candidateFacingDetailsSlateValue);

  const { data: tokens } = useTokens({
    type: 'self_scheduling_request_email',
    self_scheduling_link: constructSelfSchedulingTokensPayload(arbitraryApplication, stageInterview?.name || '', durationMinutes, candidateFacingName, candidateFacingDetails, users),
  });

  const { data: interviewBatches } = useInterviewBatches({ stage_interview_id: stageInterviewId });

  const stageInterviewOptions = useMemo<Option<string>[]>(() => {
    return (stage!.stage_interviews || [])
    .filter(({ ats_schedulable, deleted, inline }) => !deleted && (ats_schedulable === null || ats_schedulable) && !inline)
    .map((stageInterview) => ({
      value: stageInterview.id,
      label: stageInterview.name,
    }));
  }, [stage?.stage_interviews]);

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleStageInterviewChange = async (option: OnChangeValue<Option<string>, false>) => {
    setStageInterviewId(option!.value);

    const stageInterview = stageInterviewsById[option!.value];
    if (!stageInterview) {
      return;
    }

    const {
      businessHours,
      duration,
      feedbackFormId,
      interviewerTemplates,
      liveCodingEnabled,
      candidateFacingName,
      candidateFacingDetails,
    } = await defaultValuesForStageInterview(queryClient, account!, arbitraryApplication, users, stageInterview);

    setInterviewBatchId('');
    setBusinessHours(businessHours);
    setDurationMinutes(duration);
    setCandidateFacingName(candidateFacingName || '');
    setCandidateFacingDetails(candidateFacingDetails || '');
    setFeedbackFormId(feedbackFormId);
    setIsLiveCodingEnabled(liveCodingEnabled);
    setInterviewerTemplates(interviewerTemplates);
  };

  const handleIsInterviewBatchSelected = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInterviewBatchSelected(e.target.checked);
    setInterviewBatchId('');
  };
  const handleInterviewBatchChange = (option: OnChangeValue<Option<string>, false>) => setInterviewBatchId(option?.value || '');
  const handleDurationChange = (duration: number) => setDurationMinutes(duration);
  const handleCandidateFacingNameChange = (e: ChangeEvent<HTMLInputElement>) => setCandidateFacingName(e.target.value);
  const handleFeedbackFormIdChange = (option: OnChangeValue<Option<string>, false>) => setFeedbackFormId(option?.value);
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleSchedulingIntervalMinutesChange = (duration: number) => setSchedulingIntervalMinutes(duration);
  const handleRescheduleEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsRescheduleEnabled(e.target.checked);
  const handleRescheduleNoticeHoursChange = (duration: number) => setRescheduleNoticeHours(duration / 60);
  const handleCancelEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsCancelEnabled(e.target.checked);
  const handleCancelNoticeHoursChange = (duration: number) => setCancelNoticeHours(duration / 60);
  const handleFollowUpEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsFollowUpEnabled(e.target.checked);
  const handleFollowUpDelayDaysChange = (duration: number) => setFollowUpDelayDays(duration);
  const handleVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsVideoConferencingEnabled(e.target.checked);
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => setZoomHostFilters(filters);
  const handleLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsLiveCodingEnabled(e.target.checked);

  const handleNext = () => {
    setError(null);

    // Check that self-scheduling request link is included in self-scheduling request email
    if (isEmailEnabled && !/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(emailTemplate!.body)) {
      setError(new Error('The template must include the SelfSchedulingRequest.Link token.'));
      return false;
    }

    // Check that there is at least one set of business hours.
    if (businessHours.length === 0) {
      setError(new Error('At least one day is required for working hours.'));
      return false;
    }

    setSelfSchedulingLink((prev) => ({
      ...prev,
      schedule_template: {
        ...prev.schedule_template,
        business_hours: businessHours,
        allowed_times: allowedTimes.length > 1 ? allowedTimes.filter(({ start_time }) => Boolean(start_time)) : allowedTimes, // This includes the first blank allowed time without a start time.
        scheduling_interval_minutes: schedulingIntervalMinutes,
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })) || undefined,
        self_scheduling_advanced_notice_hours: advancedNoticeHours,
        self_scheduling_reschedule_enabled: isRescheduleEnabled,
        self_scheduling_reschedule_notice_hours: rescheduleNoticeHours,
        self_scheduling_cancel_enabled: isCancelEnabled,
        self_scheduling_cancel_notice_hours: cancelNoticeHours,
        self_scheduling_email_follow_up_templates: isFollowUpEnabled ? [{
          delay_days: followUpDelayDays,
        }] : undefined,
        self_scheduling_request_email_template: isEmailEnabled ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Self-Scheduling Request Email',
        } : undefined,
      },
      stage_interview: {
        ...prev.stage_interview,
        id: stageInterviewId,
        feedback_form_id: feedbackFormId,
        interview_template: {
          ...prev.stage_interview.interview_template,
          duration_minutes: durationMinutes,
          live_coding_enabled: isLiveCodingEnabled,
          candidate_facing_name: candidateFacingName,
          candidate_facing_details: candidateFacingDetails && candidateFacingDetails !== '<br>' ? candidateFacingDetails : undefined,
          interviewer_templates: interviewerTemplates,
        },
      },
      interview_batch_id: interviewBatchId || undefined,
    }));
    setCompletedStep(Step.Preferences + 1);
  };

  const allAppsHaveEmail = applications && applications.every((app) => Boolean(app.candidate.email));

  return (
    <Breadcrumb
      data={{
        title: '1. Preferences',
        pathname: pathForSelfScheduling(isBulk, applicationIDs, 'preferences'),
      }}
    >
      <MultiStepFormStep
        className="form-step-preferences"
        isFirstStep
        nextButtonIsDisabled={!allAppsHaveEmail || errorTokens.length > 0}
        nextButtonValue="Events & Emails"
        nextLocation={pathForSelfScheduling(isBulk, applicationIDs, 'events-and-emails')}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Preferences | Send {isBulk ? `${applicationIDs.length} ${pluralize('candidate', applicationIDs.length)}` : arbitraryApplication.candidate.name || 'Unknown'} a link to schedule {stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={<>
            {isBulk ? 'Some candidates don\'t ' : 'This candidate doesn\'t '}
            have an email address associated to them, so we can&apos;t send
            calendar invitations or emails. You can add an email in{' '}
            {isBulk ? capitalize(account?.ats_type) :
              <OutboundLink
                externalLinkIcon={false}
                href={constructAtsHref(
                  account!,
                  AtsHrefType.Candidate,
                  {
                    applicationAtsId: arbitraryApplication.id,
                    candidateAtsId: arbitraryApplication.candidate.ats_id,
                  }
                )}
                label="Add Email Warning"
              >
                {capitalize(account?.ats_type)}
              </OutboundLink>
            }.
            {isBulk && applications &&
              <ul className="self-scheduling-missing-email-list">
                {applications.filter((app) => !Boolean(app.candidate.email)).map((app) => (
                  <li key={app.id}>
                    <OutboundLink
                      externalLinkIcon={false}
                      href={constructAtsHref(
                        account!,
                        AtsHrefType.Candidate,
                        {
                          applicationAtsId: app.id,
                          candidateAtsId: app.candidate.ats_id,
                        }
                      )}
                      label="Add Email Warning"
                    >
                      {app.candidate.name}
                    </OutboundLink>
                  </li>
                ))}
              </ul>
            }
          </>}
          showFlash={!allAppsHaveEmail}
          type="danger"
        />
        <Flash
          message={<>This stage doesn&apos;t have any schedulable interviews configured. You can add interviews {constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: job.ats_id }) ? <OutboundLink href={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: job.ats_id })} label="ATS Interview Plan">here</OutboundLink> : <Link to={correctPath(`/app/jobs/${jobId}/stages/${stageId}/interviews`)}>here</Link>}.</>}
          showFlash={stageInterviewOptions.length === 0}
          type="warning"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message="Select the interview and interviewer to schedule. Fill out the email that we will send to the candidate to book their interview."
          showFlash
          type="info"
        />
        <StyledCard title="Interview details">
          <div className="form-container self-scheduling-interview-preferences">
            <SelectInput
              className="interview-select-input"
              isRequired
              label="Interview"
              onChange={handleStageInterviewChange}
              options={stageInterviewOptions}
              value={find(stageInterviewOptions, ['value', stageInterviewId])}
            />
            {account?.ats_type === 'lever' && (
              <FeedbackFormSelectInput
                className="feedback-form-select-input"
                label="Feedback Form"
                onChange={handleFeedbackFormIdChange}
                selectedFeedbackFormId={feedbackFormId}
              />
            )}
          </div>
          {interviewBatches && interviewBatches.total > 0 && (
            <div className="form-container self-scheduling-interview-preferences">
              <StyledInterviewBatchInputContainer>
                <CheckboxInput
                  isChecked={isInterviewBatchSelected}
                  label="Invite to superday"
                  onChange={handleIsInterviewBatchSelected}
                />
                <InterviewBatchSelectInput
                  isDisabled={!isInterviewBatchSelected}
                  isRequired={isInterviewBatchSelected}
                  onChange={handleInterviewBatchChange}
                  stageInterviewId={stageInterviewId}
                  value={interviewBatchId}
                />
              </StyledInterviewBatchInputContainer>
            </div>
          )}
          {isInterviewBatchSelected ? (
            <StyledInterviewDetailsBatchMessage>
              Interview details are linked to the times for the&nbsp;
              {interviewBatchId ? (
                <Link
                  target="_blank"
                  to={correctPath(`/app/superdays/${interviewBatchId}`)}
                >
                  superday
                </Link>
              ) : 'superday'}
              .
            </StyledInterviewDetailsBatchMessage>
          ) : (
            <>
              <div className="form-container self-scheduling-interview-preferences">
                <DurationInput
                  className="duration-minutes-input"
                  id="duration-minutes-input"
                  isAbbreviatedUnits
                  isRequired
                  label="Duration"
                  onChange={handleDurationChange}
                  value={durationMinutes}
                />
              </div>
              <div className="input select-input">
                <label>Interviewers</label>
                <InterviewerTemplatesForm
                  applicationId={arbitraryApplication.id}
                  interviewerTemplates={interviewerTemplates}
                  isAlwaysSingleInterviewer
                  setInterviewerTemplates={setInterviewerTemplates}
                />
              </div>
              <Flash
                message={<>To schedule video interviews, you must set up a video conferencing integration <Link to={correctPath('/app/integrations')}>here</Link>.</>}
                showFlash={!account?.video_conferencing_type && isVideoConferencingEnabled}
                type="warning"
              />
              <ExpandableCheckboxInput
                isChecked={isVideoConferencingEnabled}
                label="Include video conferencing."
                onChange={handleVideoConferencingEnabledChange}
              >
                {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (
                  <ZoomHostFiltersBuilder
                    filters={zoomHostFilters}
                    helperText="We will consider Zoom conflicts of the Zoom Host when displaying available times to the candidate."
                    label="Zoom Meeting Host"
                    onChange={handleZoomHostFiltersChange}
                  />
                )}
              </ExpandableCheckboxInput>
              {account?.live_coding_type && (
                <CheckboxInput
                  className="live-coding-enabled-checkbox-input"
                  id="live-coding-enabled-checkbox-input"
                  isChecked={isLiveCodingEnabled}
                  label={`Include ${liveCodingLabels[account.live_coding_type]} link.`}
                  onChange={handleLiveCodingEnabledChange}
                />
              )}
            </>
          )}
        </StyledCard>
        {!isInterviewBatchSelected && (
          <StyledCard
            title="Additional scheduling preferences"
          >
            <div className="form-container self-scheduling-interview-preferences">
              <DurationInput
                className="advanced-notice-hours-input"
                helperText="The candidate must submit times that are at least this far in the future."
                id="advanced-notice-hours-input"
                isHoursOnly
                isRequired
                label="Advanced Notice"
                maxMinutes={178 * 60}
                onChange={handleAdvancedNoticeHoursChange}
                value={advancedNoticeHours * 60}
              />
            </div>
            <div className="form-container self-scheduling-interview-preferences">
              <DurationInput
                className="scheduling-interval-minutes-input"
                helperText="The interval that we'll use to generate possible slots for the candidate."
                id="scheduling-interval-minutes-input"
                isRequired
                label="Scheduling Options Interval"
                maxMinutes={55}
                minMinutes={5}
                onChange={handleSchedulingIntervalMinutesChange}
                value={schedulingIntervalMinutes}
              />
            </div>
            <AllowedTimesInput
              allowedTimes={allowedTimes}
              helperText="The candidate will only see options on these dates. For each row, select a range or double click to select a single day."
              label="Allowed Dates"
              setAllowedTimes={setAllowedTimes}
            />
          </StyledCard>

        )}
        <StyledCard
          title="Candidate experience"
        >
          <TextInput
            className="candidate-facing-name-text-input"
            helperText={<>This is the name of the interview in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens. Recommended if you want a more candidate-friendly name for your interview.</>}
            id="advanced-settings-candidate-facing-name"
            label="Candidate-Facing Interview Name"
            onChange={handleCandidateFacingNameChange}
            placeholder={stageInterview?.name}
            value={candidateFacingName}
          />
          {candidateFacingInterviewDetails && account?.ats_type !== ATS.Gem && (
            <div className="form-container self-scheduling-interview-preferences">
              <EditorInput
                allowTokens={false}
                className="candidate-facing-details"
                editor={candidateFacingDetailsSlateEditor}
                exampleHtmlContent={exampleCandidateFacingDetails}
                helperText={<>Optional blurb about the interview that will be included in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens below the interview name.</>}
                label="Candidate-Facing Agenda Details"
                pendingPreviewMessage="This token will be filled in when the candidate schedules their interview."
                setValue={setCandidateFacingDetailsSlateValue}
                type="candidate_calendar_event"
                value={candidateFacingDetailsSlateValue}
              />
            </div>
          )}
          <ExpandableCheckboxInput
            isChecked={isRescheduleEnabled}
            label="Allow candidate to reschedule."
            onChange={handleRescheduleEnabledChange}
          >
            <DurationInput
              additionalText="before scheduled time"
              className="reschedule-notice-hours-input"
              id="reschedule-notice-hours-input"
              isHoursOnly
              isRequired
              label="Minimum Reschedule Notice"
              maxMinutes={178 * 60}
              onChange={handleRescheduleNoticeHoursChange}
              value={rescheduleNoticeHours * 60}
            />
          </ExpandableCheckboxInput>
          <ExpandableCheckboxInput
            isChecked={isCancelEnabled}
            label="Allow candidate to cancel."
            onChange={handleCancelEnabledChange}
          >
            <DurationInput
              additionalText="before scheduled time"
              className="cancel-notice-hours-input"
              id="cancel-notice-hours-input"
              isHoursOnly
              isRequired
              label="Minimum Cancel Notice"
              maxMinutes={178 * 60}
              onChange={handleCancelNoticeHoursChange}
              value={cancelNoticeHours * 60}
            />
          </ExpandableCheckboxInput>
          <ExpandableCheckboxInput
            isChecked={isFollowUpEnabled}
            label="Automatically follow up with the candidate if they don't schedule an interview."
            onChange={handleFollowUpEnabledChange}
          >
            <DayDurationInput
              additionalText="after initial request"
              className="follow-up-days-input"
              dayLabel="weekday"
              id="follow-up-days-input"
              isRequired
              label="Days Before Sending Follow-up"
              min={1}
              onChange={handleFollowUpDelayDaysChange}
              value={followUpDelayDays}
            />
          </ExpandableCheckboxInput>
          <ErrorTokenFlash errorTokens={errorTokens} />
          <EmailTemplateForm
            emailTemplate={emailTemplate}
            enabledCheckboxLabel="Send self-scheduling request email through Gem Scheduling."
            isDisabled={!canSendEmail}
            isEnabled={isEmailEnabled}
            pendingPreviewMessage="This token will be filled in when the request is sent to the candidate."
            setEmailTemplate={setEmailTemplate}
            setErrorTokens={setErrorTokens}
            setIsEnabled={setIsEmailEnabled}
            tokens={tokens}
            type="self_scheduling_request_email"
          />
        </StyledCard>
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default PreferencesStep;
