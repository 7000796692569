import Moment from 'moment-timezone';
import { isEmpty, orderBy } from 'lodash';
import { useMemo } from 'react';

import Avatar from '../../../../../../library/data-display/Avatar';
import CalendarEventPreview from '../../../../../../library/data-display/CalendarEventPreview';
import ListItem from '../../../../../../library/data-display/ListItem';
import OutboundLink from '../../../../../../library/navigation/OutboundLink';
import Table from '../../../../../../library/data-display/Table';
import { formatMoment, TimeFormat } from '../../../../../../../libraries/time';
import { isTraineeSlot } from '../../../../../../../libraries/training';
import { liveCodingLogos, rsvpAvatarStatus } from '../../../../../../../types';
import { useSession } from '../../../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { EditableInterview, EditableSchedule } from '../../../../../../../types';
import type { TableSchema } from '../../../../../../library/data-display/Table';

interface Props {
  schedule: EditableSchedule;
}

const ScheduleTable = ({ schedule }: Props) => {
  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<EditableInterview>>(() => [{
    header: 'Time',
    displayValue: ({ interview_template: { duration_minutes }, start_time }) => (
      <span>
        {formatMoment(Moment.tz(start_time, schedule.timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(start_time, schedule.timezone).add(duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
      </span>
    ),
  }, {
    header: 'Interview',
    displayValue: ({ name, live_coding_url, interview_template: { live_coding_enabled } }) => (
      <div className="interview-name">
        <span>{name}</span>
        {account?.live_coding_type && live_coding_enabled && (
          <OutboundLink
            externalLinkIcon={false}
            href={live_coding_url || 'To be generated'}
            label="Schedule Table Live Coding Link"
          >
            <Avatar
              alt={live_coding_url || 'To be generated'}
              img={liveCodingLogos[account.live_coding_type]}
              size="small"
            />
          </OutboundLink>
        )}
      </div>
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ feedback_form }) => {
      if (!feedback_form) {
        return <span className="no-template">No feedback form</span>;
      }
      return feedback_form.name;
    },
  }, {
    header: 'Interview Panel',
    displayValue: ({ interviewers }) => (
      <div className="interview-panel">
        {isEmpty(interviewers) ?
          <ListItem label={<i>No interviewers</i>} /> :
          (interviewers || []).map(({ interviewer_template, user_id, rsvp }, i) => (
            <ListItem
              key={`interviewer-${i}`}
              label={
                <span>
                  {users[user_id!]?.name || users[user_id!]?.email}
                  {interviewer_template.optional && <span className="optional-interviewer"> (Optional)</span>}
                  {isTraineeSlot(interviewer_template.interviewer_filters || []) && <span className="trainee-interviewer"> (Trainee)</span>}
                </span>
              }
              leftIcon={
                <Avatar
                  size="small"
                  status={rsvpAvatarStatus[rsvp].status}
                  tooltipHelperText={rsvpAvatarStatus[rsvp].tooltipHelperText}
                  userId={user_id!}
                />
              }
            />
          ))
        }
      </div>
    ),
  }], [account, users]);

  return (
    <Table
      collapseRowTooltipText="Hide internal calendar event"
      data={orderBy(schedule.interviews, ({ start_time }) => Moment(start_time))}
      displayExpandedContent={(interview) => (
        <CalendarEventPreview
          description={interview.description}
          directoryCalendarId={schedule.scheduling_calendar_email}
          directoryEventId={interview.interviewer_event_ical_uid}
          endTime={Moment.tz(interview.start_time, schedule.timezone).add(interview.interview_template.duration_minutes, 'minutes').toDate()}
          guests={(interview.interviewers || []).map(({ interviewer_template: { description, optional, interviewer_filters }, user_id, rsvp }) => ({
            id: user_id!,
            description: `${description || 'Interviewer'}${isTraineeSlot(interviewer_filters || []) ? ' (Trainee)' : ''}${optional ? ' (Optional)' : ''}`,
            rsvp,
          }))}
          isVideoConferencingEnabled={schedule.schedule_template.video_conferencing_enabled}
          location={(schedule.schedule_template.video_conferencing_enabled || account?.interviewer_event_uses_candidate_location) ? schedule.candidate_event_location : undefined}
          roomId={interview.room_id}
          startTime={Moment.tz(interview.start_time, schedule.timezone).toDate()}
          timezone={schedule.timezone}
          title={interview.title}
        />
      )}
      expandRowTooltipText="Show internal calendar event"
      layout="vertical"
      schema={schema}
    />
  );
};

export default ScheduleTable;
