import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useState } from 'react';

import Button from '../../library/inputs/Button';
import Flash from '../../library/utils/Flash';
import useSyncStateWithQuery from '../../../hooks/use-sync-state-with-query';
import { adminConsentParams } from '../../../hooks/queries/auth';

import type { MouseEvent, ReactNode } from 'react';

const ERROR_CODES: { [error: string]: ReactNode } = {
  // the user declined to consent
  consent_required: <>It looks like you didn&apos;t grant admin consent. Please click the button below again to do so.</>,
  // any kind of 500
  server_error: <>Looks like something went wrong. Try signing in again, and if it continues to happen, <Link to="/contact">let us know</Link>.</>,
};

const ConsentInner = ({ variant }: { variant: 'microsoft365' | 'microsoft365-us' }) => {
  const queryClient = useQueryClient();

  const [, queryError] = useSyncStateWithQuery<string>('error', '');
  const [, querySuccess] = useSyncStateWithQuery<string>('success', '');

  const [error, setError] = useState(ERROR_CODES[queryError] ? { message: ERROR_CODES[queryError] } : null);
  const [isFetching, setIsFetching] = useState(false);

  const handleConsent = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(adminConsentParams(variant));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  const isSuccess = Boolean(querySuccess);

  const microsoftLabel = `Microsoft 365${variant === 'microsoft365-us' ? ' Government' : ''}`;

  return (
    <div className="consent-container">
      <Helmet>
        <title>{microsoftLabel} Admin Consent | Gem Scheduling</title>
      </Helmet>
      <h1>
        {microsoftLabel} Admin Consent
      </h1>
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message="Admin consent has been granted! You can let your team member who is in charge of the Gem Scheduling integration know that they can continue with the process."
        showFlash={isSuccess}
        type="success"
      />
      <p>
        Gem Scheduling is a platform that automates the process of scheduling interviews. To provide that level of automation, we rely on access to data within your {microsoftLabel} account. Some of the permissions needed to access that data require the consent of a {microsoftLabel} administrator.
      </p>
      <Flash
        message={`If you are not an administrator for your company's ${microsoftLabel} account, you should send a link to this page to someone who is.`}
        showFlash
        type="info"
      />
      <p>
        The permissions that we request are mostly centered around users, calendars, and places (rooms).
      </p>
      <ul>
        <li>
          <b>Maintain access to data you have given it access to</b>: This allows Gem Scheduling to use the permissions granted to it to sync the approved data in the background, allowing the experience using Gem Scheduling to be more streamlined. This is an <i>application</i> permission, which means it can be used without a signed-in user.
        </li>
        <li>
          <b>Read user calendars</b>: This allows Gem Scheduling to only read events on all calendars. We use this information when generating a viable time and panel for an interview to find what times interviewers are available. This is an <i>application</i> permission, which means it can be used without a signed-in user.
        </li>
        <li>
          <b>Read user mailbox settings</b>: This allows Gem Scheduling to read all users&apos; timezones and working hours. We use this information when generating a viable time and panel for an interview to find what times interviewers are available. This is an <i>application</i> permission, which means it can be used without a signed-in user.
        </li>
        <li>
          <b>Read all company places</b>: This allows Gem Scheduling to list all conference rooms within the account. We use this information to be able to see what times rooms are available when a room is needed for an interview. This is an <i>application</i> permission, which means it can be used without a signed-in user.
        </li>
        <li>
          <b>Read all users&apos; full profiles</b>: This allows Gem Scheduling to list all users within the account. We use this information to construct the list of potential interviewers. This is an <i>application</i> permission, which means it can be used without a signed-in user.
        </li>
      </ul>
      <Button
        color="orange"
        isDisabled={isFetching}
        onClick={handleConsent}
        size="large"
        type="submit"
        value="Grant Admin Consent"
      />
    </div>
  );
};

export default ConsentInner;
