import Config from '../config';

class ZendeskError extends Error {
  // The response status code.
  public status: number;

  constructor (message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'ZendeskError';
  }
}

class Zendesk {
  private uri: string;

  constructor () {
    this.uri = Config.ZENDESK_API_HOST;
  }

  async checkStatus (response: Response) {
    const resp = await response.json();
    if (response.status >= 200 && response.status < 300) {
      return resp;
    }
    const message = resp.error ? resp.error.message : resp.message || 'Something went wrong. Contact us at support@interviewplanner.com.';
    throw new ZendeskError(message, response.status);
  }

  async createRequest (name: string, email: string, comment: string, subject: string) {
    const method = 'POST';
    const uri = `${this.uri}/requests`;
    const headers = {
      'content-type': 'application/json; charset=utf-8',
    };
    const body = {
      request: {
        requester: {
          name,
          email,
        },
        subject,
        comment: {
          body: comment,
        },
      },
    };

    const response = await fetch(uri, {
      method,
      headers,
      body: JSON.stringify(body),
    });

    return this.checkStatus(response);
  }
}

export default new Zendesk();
