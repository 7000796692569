import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ceil, min, range } from 'lodash';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect } from 'react';

import Button from '../../inputs/Button';
import CheckboxInput from '../../inputs/CheckboxInput';
import SelectInput from '../../inputs/SelectInput';
import Tooltip from '../../utils/Tooltip';

import type { ChangeEvent, ReactNode } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../inputs/SelectInput/types';

interface Props {
  additionalElements?: ReactNode;
  prefixedAdditionalElements?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  dataDescriptor?: string;
  numColumns: number;
  // pageNumber is required when onPageNumberChange is passed in..
  onPageNumberChange?: (pageNumber: number) => void;
  onShowArchivedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  // onPageNumberChange is required when pageNumber is passed in.
  pageNumber?: number;
  pageSize?: number;
  showArchived?: boolean;
  showArchivedLabel?: string;
  totalCount?: number;
}

const TableHeader = ({
  additionalElements,
  prefixedAdditionalElements,
  isDisabled,
  isLoading,
  dataDescriptor = 'results',
  numColumns,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  pageSize = 10,
  showArchived,
  showArchivedLabel = 'Show archived',
  totalCount = 0,
}: Props) => {
  const pageCount = totalCount === 0 || pageSize === 0 ? 1 : ceil(totalCount / pageSize);
  const number = pageNumber !== undefined && pageNumber < 1 ? 1 : pageNumber || -1;
  const pageIndex = number - 1;
  const offset = pageSize * pageIndex;
  const resultRangeStart = offset + 1;
  const resultRangeEnd = min([offset + pageSize, totalCount]);
  const descriptor = totalCount === 1 ? dataDescriptor.slice(0, -1) : dataDescriptor;

  useEffect(() => {
    if (!isLoading && pageCount && number > pageCount) {
      onPageNumberChange?.(pageCount);
    }
  }, [isLoading, number, onPageNumberChange, pageCount, number]);

  const handlePreviousPageClick = useCallback(() => {
    onPageNumberChange?.(number - 1);
  }, [onPageNumberChange, number]);

  const handleNextPageClick = useCallback(() => {
    onPageNumberChange?.(number + 1);
  }, [onPageNumberChange, number]);

  const handlePageNumberChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    // Option can't be null since this select isn't clearable.
    onPageNumberChange?.(parseInt(option!.value, 10));
  }, [onPageNumberChange]);

  const pageOptions = onPageNumberChange && range(1, pageCount + 1).map((i) => ({
    label: `${i}`,
    value: `${i}`,
  }));

  return (
    <tr>
      <td colSpan={numColumns}>
        <div className="table-pagination-header">
          <div className="left-container">
            <div>
              {onPageNumberChange && pageSize && totalCount > pageSize ?
                <span><b>{resultRangeStart}&ndash;{resultRangeEnd}</b> of </span> :
                null
              }
              <b>{totalCount}</b> {descriptor}
            </div>
            {prefixedAdditionalElements}
          </div>
          {((onPageNumberChange && pageOptions) || onShowArchivedChange) &&
            <div className="right-container">
              {onShowArchivedChange &&
                <CheckboxInput
                  id="table-pagination-header-show-archived"
                  isChecked={showArchived}
                  isDisabled={isDisabled}
                  label={showArchivedLabel}
                  onChange={onShowArchivedChange}
                />
              }
              {onPageNumberChange && pageOptions && (
                <div className="pagination-container">
                  <Button
                    color="gray"
                    iconRight={<FontAwesomeIcon icon={faCaretLeft} />}
                    isDisabled={number === 1 || isDisabled}
                    onClick={handlePreviousPageClick}
                    size="large"
                    tooltip={number === 1 ?
                      undefined :
                      <Tooltip
                        id="table-previous-button"
                        position="top"
                        value={'Previous page'}
                      />
                    }
                  />
                  {number}
                  <Button
                    color="gray"
                    iconRight={<FontAwesomeIcon icon={faCaretRight} />}
                    isDisabled={number === pageCount || isDisabled}
                    onClick={handleNextPageClick}
                    size="large"
                    tooltip={number === pageCount ?
                      undefined :
                      <Tooltip
                        id="table-next-button"
                        position="top"
                        value={'Next page'}
                      />
                    }
                  />
                  <SelectInput
                    id="select-input-table-pagination-header"
                    isDisabled={isDisabled}
                    isSearchable={false}
                    label="Jump to page"
                    onChange={handlePageNumberChange}
                    options={pageOptions}
                    value={pageOptions[pageIndex]}
                  />
                </div>
              )}
              {additionalElements}
            </div>
          }
        </div>
      </td>
    </tr>
  );
};

export default TableHeader;
