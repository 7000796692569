import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';

import Button from '../../../../library/inputs/Button';
import CopyToClipboardButton from '../../../../library/inputs/CopyToClipboardButton';
import Flash from '../../../../library/utils/Flash';
import Table from '../../../../library/data-display/Table';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import { Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';
import { useSelfSchedulingApplications } from './helpers';
import { correctPath } from 'libraries/gem';
import pluralize from 'libraries/pluralize';

import type { BulkCreateSelfSchedulingLinksError } from '../../../../../hooks/queries/self-scheduling-links';

const BulkResultsStep = () => {
  const {
    completedStep,
    bulkSelfSchedulingIds,
    bulkSelfSchedulingErrors,
  } = useNewSelfSchedulingLink();

  const { applicationIDs, applications, arbitraryApplication, isLoading } = useSelfSchedulingApplications();
  const stage = arbitraryApplication!.current_stage;

  if (completedStep < Step.BulkResults) {
    return <Redirect to={correctPath('/app/candidates')} />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const errorForApplication = (appId: string, errors: { [appId: string]: BulkCreateSelfSchedulingLinksError }) => {
    if (errors[appId]) {
      return errors[appId].error.message;
    }

    return 'Send failed';
  };

  const tableData = applications!.map((application) => ({
    application,
    status: Object.keys(bulkSelfSchedulingIds).includes(application.id) ? 'Success' : errorForApplication(application.id, bulkSelfSchedulingErrors),
    selfSchedulingLinkId: bulkSelfSchedulingIds[application.id],
  }));
  const numErrors = Object.keys(bulkSelfSchedulingErrors).length;

  return (
    <Breadcrumb
      data={{
        title: '4. Results',
        pathname: correctPath('/app/candidates/bulk-self-schedule/results'),
      }}
    >
      <div className="form-step-bulk-results">
        <Helmet>
          <title>4. Results | Send {`${applicationIDs.length}`} {pluralize('candidate', applicationIDs.length)} a link to schedule {stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={`Self-scheduling links sent to ${Object.keys(bulkSelfSchedulingIds).length} ${pluralize('candidate', Object.keys(bulkSelfSchedulingIds).length)}`}
          showFlash
          type="success"
        />
        {numErrors > 0 &&
          <Flash
            message={`${numErrors === 1 ? 'An error' : 'Errors'} occurred when sending self-scheduling links to ${numErrors} ${pluralize('candidate', numErrors)}`}
            showFlash
            type="danger"
          />
        }
        <Table
          data={tableData}
          layout="vertical"
          schema={[
            {
              header: 'Candidate',
              displayValue: /* eslint-disable react/display-name, react/prop-types */ ({ application }) => (
                <Link to={correctPath(`/app/candidates/${application.id}`)}>
                  {application.candidate.name}
                </Link>
              ),
            },
            {
              header: 'Status',
              displayValue: ({ status }) => (
                <div>
                  {status}
                </div>
              ),
            },
            {
              header: 'Link',
              displayValue: ({ selfSchedulingLinkId }) => {
                const link = correctPath(`/schedule/${selfSchedulingLinkId}`);
                return (
                  <div className="self-scheduling-link">
                    <Link target="_blank" to={link}>
                      {window.origin}{link}
                    </Link>
                    <CopyToClipboardButton textToCopy={`${window.origin}${link}`} />
                  </div>
                );
              },
            },
          ]}
        />
        <p className="bulk-results-action-section">
          <Link to={correctPath('/app/candidates')}>
            <Button
              color="gem-outline"
              size="large"
              value="Return to Candidates"
            />
          </Link>
        </p>
      </div>
    </Breadcrumb>
  );
};

export default BulkResultsStep;
