import { useMutation, useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { ATS, Sync } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveLatestSync = 'RetrieveLatestSync',
}

export const useLatestSync = (source?: ATS, options: UseQueryOptions<Sync | null, Error> = {}) => {
  return useQuery<Sync | null, Error>([QueryKey.RetrieveLatestSync, source], () => {
    return InterviewPlanner.request('GET', '/syncs/latest', null, { source });
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(source),
    ...options,
  });
};

export interface StartSyncPayload {
  source: ATS;
}

interface StartSyncMutationVariables {
  payload?: StartSyncPayload;
}

export const useStartSync = () => {
  return useMutation<{ success: boolean }, Error, StartSyncMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/syncs', payload);
  });
};
