import { useCallback, useEffect, useState } from 'react';

import Banner from './Banner';
import Config from '../../../config';
import { useVersion } from '../../../hooks/queries/version';

const DEPLOY_THRESHOLD_MS = 5 * 60 * 60 * 1000; // 5h

const NewVersionBanner = () => {
  const { data: version } = useVersion();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!version) {
      // The version hasn't loaded yet, so don't do anything.
      return;
    }

    if (version.version === Config.VERSION) {
      // The versions are the same, so don't do anything.
      return;
    }

    // We've detected a new version has been deployed. However, we can't just
    // ask the user to reload the page because we might be in the middle of a
    // deploy, so if they refresh now, they might get the old version again
    // (because we do rolling deploys), and then they'd have to refresh again.
    // To avoid this issue, we take the provided deploy time and ensure that we
    // only display the banner a certain amount of time after deploy so that we
    // have sufficient time to finish the deploy.

    const now = Date.now();
    const deployed = version.date.getTime();
    const timeSinceDeployMs = now - deployed;

    if (timeSinceDeployMs >= DEPLOY_THRESHOLD_MS) {
      // We're past the threshold, so show the message immediately.
      analytics.track('New Version Banner Shown', { version: version?.version, delay: false });
      setShowBanner(true);
    } else {
      // We haven't hit the threshold yet, so we set a timeout to show the
      // banner whenever we do.
      const id = setTimeout(() => {
        analytics.track('New Version Banner Shown', { version: version?.version, delay: true });
        setShowBanner(true);
      }, DEPLOY_THRESHOLD_MS - timeSinceDeployMs);

      return () => clearTimeout(id);
    }
  }, [version?.version]);

  const handleClick = useCallback(() => {
    analytics.track('New Version Banner Link Clicked', { version: version?.version });
    window.location.reload();
  }, [version?.version]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner type="warning">
      A new version of InterviewPlanner is available. <a onClick={handleClick} role="button">Hard-refresh this page</a> to stay up to date.
    </Banner>
  );
};

export default NewVersionBanner;
