import Config from '../config';
import { ATS, ChatTool } from '../types';

import type { Account } from '../types';

export enum AtsHrefType {
  Candidate = 'candidate',
  Job = 'job',
  InterviewPlan = 'interview plan',
}

export interface AtsIds {
  jobAtsId?: string;
  candidateAtsId?: string;
  applicationAtsId?: string;
}

export function constructAtsHref (account: Account, type: `${AtsHrefType}`, atsIds: AtsIds): string {
  switch (account.ats_type) {
    case ATS.Gem:
      switch (type) {
        case AtsHrefType.Candidate:
          return `/candidate/${atsIds.candidateAtsId}/applications/${atsIds.applicationAtsId}`;
        case AtsHrefType.Job:
          return `/ats/jobs/${atsIds.jobAtsId}`;
        case AtsHrefType.InterviewPlan:
          return `/ats/jobs/${atsIds.jobAtsId}/interview-plan`;
      }
      break;
    case ATS.Greenhouse:
      switch (type) {
        case AtsHrefType.Candidate:
          return `https://${account.greenhouse_subdomain}.greenhouse.io/people/${atsIds.candidateAtsId}?application_id=${atsIds.applicationAtsId}`;
        case AtsHrefType.Job:
          return `https://${account.greenhouse_subdomain}.greenhouse.io/sdash/${atsIds.jobAtsId}`;
        case AtsHrefType.InterviewPlan:
          return `https://${account.greenhouse_subdomain}.greenhouse.io/plans/${atsIds.jobAtsId}/process/edit`;
      }
      break;
    case ATS.Lever:
      switch (type) {
        case AtsHrefType.Candidate:
          return `${Config.LEVER_HOST}/candidates/${atsIds.candidateAtsId}`;
        case AtsHrefType.Job:
          return `${Config.LEVER_HOST}/jobs/postings/edit/${atsIds.jobAtsId}`;
      }
  }

  // Shouldn't actually happen.
  return '';
}

export enum ChatHrefType {
  HiringChannel = 'hiring_channel',
}

export interface ChatIds {
  hiringChannelChatId?: string;
}

export function constructChatHref (account: Account, type: `${ChatHrefType}`, chatIds: ChatIds): string {
  switch (account.chat_type) {
    case ChatTool.Slack:
      switch (type) {
        case ChatHrefType.HiringChannel:
          return `https://slack.com/archives/${chatIds.hiringChannelChatId}`;
      }
  }

  // Shouldn't actually happen.
  return '';
}
