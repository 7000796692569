export enum ATS {
  Gem = 'gem',
  Greenhouse = 'greenhouse',
  Lever = 'lever',
  SuccessFactors = 'success_factors',
}

export const atsLabels: Record<ATS, string> = {
  [ATS.Gem]: 'Gem',
  [ATS.Greenhouse]: 'Greenhouse',
  [ATS.Lever]: 'Lever',
  [ATS.SuccessFactors]: 'SuccessFactors',
};

export const atsLogos: Record<ATS, string> = {
  [ATS.Gem]: '/scheduling/src/assets/images/gem-logo.svg',
  [ATS.Greenhouse]: '/scheduling/src/assets/images/greenhouse-logo.svg',
  [ATS.Lever]: '/scheduling/src/assets/images/lever-logo.svg',
  [ATS.SuccessFactors]: '/scheduling/src/assets/images/success-factors-logo.svg',
};

export enum ChatTool {
  Slack = 'slack',
}

export const chatLabels: Record<ChatTool, string> = {
  [ChatTool.Slack]: 'Slack',
};

export const chatLogos: Record<ChatTool, string> = {
  [ChatTool.Slack]: 'https://logo.clearbit.com/slack.com',
};

export enum Directory {
  Google = 'google',
  Microsoft = 'microsoft365',
}

export const directoryLabels: Record<Directory, string> = {
  [Directory.Google]: 'Google',
  [Directory.Microsoft]: 'Microsoft 365',
};

export const directoryCalendarLabels: Record<Directory, string> = {
  [Directory.Google]: 'Google Calendar',
  [Directory.Microsoft]: 'Outlook Calendar',
};

export const directoryCalendarLogos: Record<Directory, string> = {
  [Directory.Google]: '/scheduling/src/assets/images/google-calendar-logo.svg',
  [Directory.Microsoft]: '/scheduling/src/assets/images/outlook-calendar-logo.svg',
};

export const directoryEmailLabels: Record<Directory, string> = {
  [Directory.Google]: 'Gmail',
  [Directory.Microsoft]: 'Outlook',
};

export const directoryEmailLogos: Record<Directory, string> = {
  [Directory.Google]: '/scheduling/src/assets/images/gmail-logo.svg',
  [Directory.Microsoft]: '/scheduling/src/assets/images/outlook-mail-logo.svg',
};

export enum LiveCodingTool {
  CoderPad = 'coderpad',
  HackerRank = 'hackerrank',
  CodeSignal = 'codesignal',
}

export const liveCodingLabels: Record<LiveCodingTool, string> = {
  [LiveCodingTool.CoderPad]: 'CoderPad',
  [LiveCodingTool.HackerRank]: 'HackerRank',
  [LiveCodingTool.CodeSignal]: 'CodeSignal',
};

export const liveCodingLogos: Record<LiveCodingTool, string> = {
  [LiveCodingTool.CoderPad]: 'https://logo.clearbit.com/coderpad.io',
  [LiveCodingTool.HackerRank]: 'https://logo.clearbit.com/hackerrank.com',
  [LiveCodingTool.CodeSignal]: 'https://logo.clearbit.com/codesignal.com',
};

export enum VideoConferencingTool {
  GoogleMeet = 'meet',
  MicrosoftTeams = 'teams',
  Zoom = 'zoom',
}

export const externalVideoConferencing: VideoConferencingTool[] = [
  VideoConferencingTool.MicrosoftTeams,
  VideoConferencingTool.Zoom,
];

export const googleVideoConferencing: VideoConferencingTool[] = [
  VideoConferencingTool.GoogleMeet,
];

export const videoConferencingLabels: Record<VideoConferencingTool, string> = {
  [VideoConferencingTool.GoogleMeet]: 'Google Meet',
  [VideoConferencingTool.MicrosoftTeams]: 'Microsoft Teams',
  [VideoConferencingTool.Zoom]: 'Zoom',
};

export const videoConferencingLogos: Record<VideoConferencingTool, string> = {
  [VideoConferencingTool.GoogleMeet]: '/scheduling/src/assets/images/meet-logo.svg',
  [VideoConferencingTool.MicrosoftTeams]: 'https://logo.clearbit.com/microsoft.com',
  [VideoConferencingTool.Zoom]: 'https://logo.clearbit.com/zoom.us',
};

export interface Calendar {
  id: string;
  name: string;
  description: string;
  timezone: string;
  allowed_video_conferencing_types: ('hangouts' | VideoConferencingTool.GoogleMeet)[];
}
