import { find, startCase } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import Config from '../../../config';
import Flash from '../../library/utils/Flash';
import OutboundLink from '../../library/navigation/OutboundLink';
import Section from '../../library/layout/Section';
import SelectInput from '../../library/inputs/SelectInput';
import TextInput from '../../library/inputs/TextInput';
import { ATS, liveCodingLabels, LiveCodingTool } from '../../../types';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';

import type { ChangeEvent } from 'react';
import type { Group, Option } from '../../library/inputs/SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { SelectInstance } from 'react-select';
import { useLDFlags } from 'hooks/use-ld-flags';

const liveCodingOptions: Option<`${LiveCodingTool}`>[] = [
  {
    value: LiveCodingTool.CoderPad,
    label: liveCodingLabels[LiveCodingTool.CoderPad],
  },
  {
    value: LiveCodingTool.HackerRank,
    label: liveCodingLabels[LiveCodingTool.HackerRank],
  },
  {
    value: LiveCodingTool.CodeSignal,
    label: liveCodingLabels[LiveCodingTool.CodeSignal],
  },
];

const liveCodingHelpLinks: Record<string, string> = {
  [LiveCodingTool.HackerRank]:
    'https://support.gem.com/hc/en-us/articles/23491714027927-How-do-I-connect-my-HackerRank-account',
};

const gemAtsIntegrationSettingsLink = `${Config.GEM_HOST}/ats/settings/integrations`;

const IntegrationsLiveCodingSection = () => {
  const { data } = useAccount();
  const account = data!;
  const hasLiveCodingIntegration = Boolean(account.live_coding_type);
  const { allowCodesignal } = useLDFlags();

  const [isEditing, setIsEditing] = useState(false);
  const [liveCodingType, setLiveCodingType] = useState<
    `${LiveCodingTool}` | ''
  >(account.live_coding_type || '');
  const [liveCodingApiKey, setLiveCodingApiKey] = useState('');

  const updateAccountMutation = useUpdateAccount();

  const typeRef =
    useRef<
      SelectInstance<
        Option<`${LiveCodingTool}`>,
        false,
        Group<`${LiveCodingTool}`, Option<`${LiveCodingTool}`>>
      >
    >(null);

  useEffect(() => {
    setLiveCodingType(account.live_coding_type || '');
  }, [account.live_coding_type]);

  useEffect(() => {
    if (isEditing) {
      typeRef.current?.focus();
    }
  }, [isEditing]);

  const handleLiveCodingTypeChange = (
    option: OnChangeValue<Option<`${LiveCodingTool}`>, false>,
  ) => setLiveCodingType(option ? option.value : '');
  const handleLiveCodingApiKeyChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLiveCodingApiKey(e.target.value);

  const handleEdit = () => {
    setLiveCodingApiKey('');
    setIsEditing(true);
  };

  const handleCancel = () => {
    setLiveCodingType(account.live_coding_type || '');
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          live_coding_type: liveCodingType,
          live_coding_api_key: liveCodingApiKey,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Live coding"
    >
      <div className="integrations-live-coding-form">
        <Flash
          message="Connect to your live coding tool to automatically generate links for technical interviews."
          showFlash={!hasLiveCodingIntegration}
          type="info"
        />
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={updateAccountMutation.isSuccess}
          type="success"
        />
        <Flash
          message={updateAccountMutation.error?.message}
          showFlash={updateAccountMutation.isError}
          type="danger"
        />
        <Flash
          message={
            <span>
              Configure your CodeSignal integration from{' '}
              <a href={gemAtsIntegrationSettingsLink} target="blank">
                Gem ATS settings
              </a>
              .
            </span>
          }
          showFlash={liveCodingType === LiveCodingTool.CodeSignal && account.ats_type === ATS.Gem}
          type="info"
        />
        <div className="form-container">
          <SelectInput
            className="input-live-coding-type"
            isClearable
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            label="Live Coding Type"
            onChange={handleLiveCodingTypeChange}
            options={liveCodingOptions.filter(
              (option) =>
                option.value !== LiveCodingTool.CodeSignal || allowCodesignal,
            )}
            placeholder="Select your live coding tool"
            ref={typeRef}
            value={find(liveCodingOptions, ['value', liveCodingType])}
          />
          {(liveCodingType !== LiveCodingTool.CodeSignal || account.ats_type !== ATS.Gem) && (
            <TextInput
              className="input-live-coding-api-key"
              helperText={
                isEditing &&
                liveCodingType &&
                liveCodingHelpLinks[liveCodingType] ? (
                    <OutboundLink
                      href={liveCodingHelpLinks[liveCodingType]}
                      label={`${startCase(liveCodingType)} API Key Helper Text`}
                    >
                    Where can I find mine?
                    </OutboundLink>
                  ) : null
              }
              isDisabled={!isEditing || updateAccountMutation.isLoading}
              isRequired={liveCodingType !== ''}
              label="API Key"
              onChange={handleLiveCodingApiKeyChange}
              placeholder="Enter your API key"
              value={
                isEditing
                  ? liveCodingApiKey
                  : hasLiveCodingIntegration
                    ? '•'.repeat(20)
                    : ''
              }
            />
          )}
        </div>
      </div>
    </Section>
  );
};

export default IntegrationsLiveCodingSection;
