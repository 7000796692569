import styled from 'styled-components';

export const StyledText = styled.span`
  cursor: pointer;

  svg.fa-circle-info {
    color: ${({ theme }) => theme.colors.silverGray};
    margin-right: .4em;
  }

  span {
    border-bottom: 1px dashed ${({ theme }) => theme.colors.silverGray};
    display: inline-block;
  }
`;
