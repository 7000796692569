import { Competitor } from 'types';
import FeatureComparisonTable from './FeatureComparisonTable';
import Section from 'components/library/layout/Section';

const ModernLoop = () => {
  return (
    <div>
      <Section title="Overview">
        <p>
          ModernLoop is a newer scheduling platform that has been around for a couple of years. They have a wide range of features and tend to price lower than some of the incumbents in the field. However, their workflows miss some key features that we have found to be important for the teams we work with.
        </p>
        <FeatureComparisonTable competitors={[Competitor.ModernLoop]} />
      </Section>
      <Section title="Candidate experience">
        <p>
          InterviewPlanner and ModernLoop both allow you to send emails that have a personal touch to them, like they were sent directly from the scheduler&apos;s inbox rather than from an automated tool.
        </p>
        <p>
          InterviewPlanner&apos;s availability request workflow allows the candidate to submit their pronouns and name pronunciation, if you enable these features. ModernLoop does not let you collect this information from the candidate. InterviewPlanner&apos;s availability request workflow lets the candidate drag to select time windows on a calendar-like interface that is both desktop and mobile-friendly, while ModernLoop&apos;s has the candidate manually enter in each date and time slot (<a href="https://modernloop.zendesk.com/hc/en-us/articles/5914389634708-Candidate-Availability-Overview#h_01G28EKK2B1EBMM05QMK13K51P" target="blank">here&apos;s a screenshot</a>).
        </p>
        <p>
          InterviewPlanner&apos;s interview templates allow you to add a candidate-facing description to each interview, which can be surfaced to the candidate in their communication so they know what to expect in each interview. ModernLoop does not have this.
        </p>
      </Section>
      <Section title="Interviewer experience">
        <p>
          InterviewPlanner and ModernLoop both take into account interviewers&apos; preferences such as interview limits, interviewer working hours, keywords for events that can be booked over. InterviewPlanner and ModernLoop both automatically try to balance interview load when selecting from a pool of interviewers.
        </p>
        <p>
          InterviewPlanner and ModernLoop both send calendar invites to interviewers when they are scheduled for interviews. Only InterviewPlanner includes the candidate&apos;s pronouns and name pronunciation (if the candidate submitted those) on interviewer invites. InterviewPlanner also includes a link to the candidate&apos;s resume directly on the invite. ModernLoop lets you customize the interviewer event description fully, while InterviewPlanner uses a best-practices template for this, which you can ask your support team if you want to modify. Both tools allow you to fully customize the interviewer event titles.
        </p>
        <p>
          Both InterviewPlanner and ModernLoop let you place schedules on hold internally before confirming the schedule for the candidate.
        </p>
        <p>
          Both platforms have a Slack integration to create private hiring channels for candidates and post messages in the channel when schedules are created or updated. Only ModernLoop sends Slack reminders to interviewers when they have an upcoming interview. (InterviewPlanner relies on Google Calendar reminders for upcoming interviews.) Only InterviewPlanner keeps the channel members up to date if you modify a schedule and automatically archives channels when candidates are rejected.
        </p>
      </Section>
      <Section title="Scheduling">
        <p>
          InterviewPlanner and ModernLoop both automatically generate schedule options that consider interviewer and candidate availability, interviewer preferences, and more. In both platforms, you can fully customize every aspect of the schedule. You can re-order interviews, swap interviewers, add video conferencing, etc. from within the scheduling workflow. You can also view interviewers&apos; calendars in a side-by-side view that looks like how it does in your calendar app.
        </p>
        <p>
          Both platforms let you configure default settings for interview loops, including interview durations, interviewers, breaks, and position constraints.
        </p>
        <p>
          Rescheduling or updating interviews is easy in both platforms, though InterviewPlanner has a more streamlined, single-screen flow for making updates and having the changes automatically synced in all candidate communication containing those details.
        </p>
        <p>
          InterviewPlanner and ModernLoop both allow you to request availability from candidates via a link where they can submit times. Only InterviewPlanner allows you to automatically send a follow-up email to a candidate if they do not respond to the availability request.
        </p>
        <p>
          InterviewPlanner and ModernLoop both allow you to send candidates self-scheduling links for single interviews. Only ModernLoop supports self-scheduling for a series of back-to-back interviews, while InterviewPlanner does not have plans to support this. (<i>Why?</i> Most teams we have spoken with prefer the coordinator to schedule these, as leaving these in the candidate&apos;s hands tend to result in higher reschedule rates and sometimes very few conflict-free schedules being shown to the candidate. However, it is on InterviewPlanner&apos;s roadmap to have a link that allows a candidate to self-schedule multiple interviews at a time that are not back-to-back). Only InterviewPlanner can automatically send a follow-up email to a candidate if they do not respond to the self-scheduling request.
        </p>
      </Section>
      <Section title="Interviewer skills and training">
        <p>
          InterviewPlanner and ModernLoop both let you tag interviewers with skills and attributes. Both platforms let you use and combine these tags to construct interviewer pools.
        </p>
        <p>
          InterviewPlanner and ModernLoop both have interviewer training programs. InterviewPlanner lets you define the phases of the program, including their names and interview requirements. ModernLoop&apos;s training program phases are more opinionated but cover most common cases, with phases called Shadow and Reverse Shadow, which you can define the interview requirements for. Both platforms let you attach a trainee to an interview such that a trainee is automatically selected whenever scheduling that interview.
        </p>
        <p>
          Only InterviewPlanner lets you schedule multiple upcoming training sessions for an interviewer, allowing you to get interviewers trained more quickly without waiting for their next training session to pass. InterviewPlanner also intelligently updates the trainee&apos;s role in all future training sessions if an interview is cancelled or rescheduled.
        </p>
        <p>
          InterviewPlanner always automatically moves trainees through the program. ModernLoop has settings to require approval by a scheduler, lead interviewer, or the trainee themselves before moving the trainee forward. Both InterviewPlanner and ModernLoop allow you to make manual changes to an interviewer&apos;s training progress, but only InterviewPlanner keeps their completed interview history intact.
        </p>
      </Section>
      <Section title="In our opinion">
        <p>
          Both ModernLoop and InterviewPlanner have a wide range of features, but InterviewPlanner provides a more intuitive, inclusive candidate experience and an interviewer training platform with fewer limitations and more customizations.
        </p>
        <p>
          InterviewPlanner has an unparalleled level of customer support that is simply impossible to find on a larger team. We create custom metrics reports, implement same-day feature requests, and our average response time is 5 minutes.
        </p>
        <p>
          We&apos;re a bootstrapped, small team that&apos;s highly customer-focused and detail-oriented, and it shows in the thoughtfulness of our product and the quality of our support. Check out <a href="https://www.interviewplanner.com/about" target="blank">our about page and company values</a> to better understand how we think about our product.
        </p>
      </Section>
    </div>
  );
};

export default ModernLoop;
