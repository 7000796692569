import { useEffect, useState } from 'react';

import CheckboxInput from '../../library/inputs/CheckboxInput';
import Flash from '../../library/utils/Flash';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import SchedulingCalendarSelectInput from '../../library/inputs/SchedulingCalendarSelectInput';
import Section from '../../library/layout/Section';
import { Directory } from '../../../types';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';
import { useCalendars } from '../../../hooks/queries/calendars';

import type { ChangeEvent } from 'react';
import type { Option } from '../../library/inputs/SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

const directoryMapping: Record<Directory, string> = {
  [Directory.Google]: 'Google',
  [Directory.Microsoft]: 'Outlook',
};

const IntegrationsCalendarSection = () => {
  const { data } = useAccount();
  const account = data!;

  const hasSchedulingCalendar = Boolean(account.scheduling_calendar_email);

  const {
    error: calendarsError,
    isLoading: isCalendarsLoading,
  } = useCalendars();

  const [isEditing, setIsEditing] = useState<boolean>(!Boolean(account.scheduling_calendar_email));
  const [schedulingCalendar, setSchedulingCalendar] = useState<string>(account.scheduling_calendar_email || '');
  const [candidateSchedulingCalendar, setCandidateSchedulingCalendar] = useState<string>(account.candidate_scheduling_calendar_email || '');
  const [isTwoWaySyncEnabled, setIsTwoWaySyncEnabled] = useState<boolean>(account.directory_type === Directory.Google && !account.exclude_schedules_attendee);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setSchedulingCalendar(account.scheduling_calendar_email || '');
  }, [account.scheduling_calendar_email]);

  useEffect(() => {
    setCandidateSchedulingCalendar(account.candidate_scheduling_calendar_email || '');
  }, [account.candidate_scheduling_calendar_email]);

  useEffect(() => {
    setIsTwoWaySyncEnabled(account.directory_type === Directory.Google && !account.exclude_schedules_attendee);
  }, [account.exclude_schedules_attendee]);

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option ? option.value : '');
  const handleCandidateSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setCandidateSchedulingCalendar(option ? option.value : '');
  const handleIsTwoWaySyncEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsTwoWaySyncEnabled(e.target.checked);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setSchedulingCalendar(account.scheduling_calendar_email || '');
    setCandidateSchedulingCalendar(account.candidate_scheduling_calendar_email || '');
    setIsTwoWaySyncEnabled(account.directory_type === Directory.Google && !account.exclude_schedules_attendee);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          scheduling_calendar_email: schedulingCalendar,
          candidate_scheduling_calendar_email: candidateSchedulingCalendar,
          exclude_schedules_attendee: !isTwoWaySyncEnabled,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable={!Boolean(calendarsError)}
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Calendar"
    >
      <div className="integrations-calendar-form">
        {isCalendarsLoading ?
          <LoadingSpinner /> :
          <>
            <Flash
              message={`Link a shared ${directoryMapping[account.directory_type]} calendar to create events and send calendar invites.`}
              showFlash={!hasSchedulingCalendar && !Boolean(calendarsError)}
              type="info"
            />
            <Flash
              isDismissible
              message="Successfully updated!"
              showFlash={updateAccountMutation.isSuccess}
              type="success"
            />
            <Flash
              message={calendarsError?.message}
              showFlash={Boolean(calendarsError)}
              type="danger"
            />
            <Flash
              message={updateAccountMutation.error?.message}
              showFlash={updateAccountMutation.isError}
              type="danger"
            />
            {!Boolean(calendarsError) &&
              <>
                <SchedulingCalendarSelectInput
                  helperText={isEditing ? 'This calendar should be accessible to all team members who schedule interviews.' : null}
                  isClearable
                  isDisabled={!isEditing || updateAccountMutation.isLoading}
                  onChange={handleSchedulingCalendarChange}
                  schedulingCalendar={schedulingCalendar}
                />
                <SchedulingCalendarSelectInput
                  helperText={isEditing ? 'This calendar should be accessible to all team members who schedule interviews.' : null}
                  isClearable
                  isDisabled={!isEditing || updateAccountMutation.isLoading}
                  label="Scheduling Calendar for Candidate Events"
                  onChange={handleCandidateSchedulingCalendarChange}
                  placeholder="Select your scheduling calendar for candidate events"
                  schedulingCalendar={candidateSchedulingCalendar}
                />
                {account.directory_type === Directory.Google && (
                  <CheckboxInput
                    helperText="We do this by adding a schedules@interviewplanner.com guest to your interview calendar invites."
                    isChecked={isTwoWaySyncEnabled}
                    isDisabled={!isEditing}
                    label="Sync interview RSVPs from Google Calendar."
                    onChange={handleIsTwoWaySyncEnabledChange}
                  />
                )}
              </>
            }
          </>}
      </div>
    </Section>
  );
};

export default IntegrationsCalendarSection;
