import Moment from 'moment-timezone';
import innerText from 'react-innertext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ceil, startCase, uniqueId } from 'lodash';
import { faAlignLeft, faBriefcase, faDoorOpen, faLock, faMapMarkerAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import OpenInDirectoryCalendarButton from 'components/library/inputs/OpenInDirectoryCalendarButton';
import ListItem from 'components/library/data-display/ListItem';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Tooltip from '../../utils/Tooltip';
import { formatDateTimeRange } from 'libraries/formatters';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { directoryCalendarLabels, rsvpAvatarStatus, videoConferencingLabels, videoConferencingLogos } from 'types';
import {
  StyledCard,
  StyledDescriptionSection,
  StyledDescriptionText,
  StyledDirectoryCalendarButtonContainer,
  StyledFontAwesomeIcon,
  StyledLocationSection,
  StyledPrivateTitleSection,
  StyledSection,
  StyledSingleLineSection,
  StyledTimeSection,
  StyledTitleSection,
  StyledVideoConferencingLogo,
} from './styles';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { RSVP, ShowAs } from 'types';
import type { ReactNode } from 'react';

const VEHO_CEO_USER_ID = '65d7ea36-2b39-4441-b5cf-bd1f28a101be';
const VEHO_CEO_ZOOM_URL = 'https://zoom.us/j/8629843194';

export interface Guest {
  id?: string;
  name?: ReactNode;
  description: ReactNode;
  optional?: boolean;
  rsvp?: `${RSVP}`;
}

interface Props {
  description?: ReactNode;
  directoryCalendarId?: string;
  directoryEventId?: string;
  endTime?: Date;
  guests?: Guest[];
  isDateDisplayed?: boolean;
  isPrivate?: boolean;
  isVideoConferencingEnabled?: boolean;
  location?: string;
  roomId?: string;
  showAs?: ShowAs;
  startTime?: Date;
  timezone?: string;
  title: ReactNode;
}

const CalendarEventPreview = ({
  description,
  directoryCalendarId,
  directoryEventId,
  endTime,
  guests,
  isDateDisplayed = false,
  isPrivate = false,
  isVideoConferencingEnabled = false,
  location,
  roomId,
  showAs,
  startTime,
  timezone,
  title,
}: Props) => {
  const users = useUsersMap({ archived: true });

  const { account } = useSession();
  const rooms = useRoomsMap({}, {
    enabled: Boolean(roomId),
  });
  const room = useMemo(() => roomId ? rooms[roomId] : undefined, [rooms, roomId]);
  const id = useMemo(() => uniqueId('calendar-event-preview-'), []);

  const duration = Moment.duration(Moment.utc(endTime).diff(Moment.utc(startTime))).asMinutes();
  const isMultiDayEvent = ceil(duration) >= 24 * 60;

  if ((guests || []).some((guest) => guest.id === VEHO_CEO_USER_ID)) {
    location = VEHO_CEO_ZOOM_URL;
  }
  return (
    <StyledCard>
      <StyledDirectoryCalendarButtonContainer>
        <OpenInDirectoryCalendarButton
          directoryCalendarId={directoryCalendarId}
          directoryEventId={directoryEventId}
        />
      </StyledDirectoryCalendarButtonContainer>
      {startTime && endTime && timezone && (
        <StyledTimeSection>
          {isMultiDayEvent || isDateDisplayed ?
            formatDateTimeRange(startTime, endTime, timezone) :
            <span>{formatMoment(Moment.tz(startTime, timezone), TimeFormat.Time)} - {formatMoment(Moment.tz(endTime, timezone), TimeFormat.TimeWithTimezone)}</span>
          }
        </StyledTimeSection>
      )}
      <StyledTitleSection>
        {isPrivate ? (
          <StyledPrivateTitleSection
            data-for={`${id}-private-tooltip`}
            data-tip
          >
            busy
            <FontAwesomeIcon icon={faLock} />
            <Tooltip
              id={`${id}-private-tooltip`}
              value={`You don't have access to view this event in ${directoryCalendarLabels[account?.directory_type!]}.`}
            />
          </StyledPrivateTitleSection>
        ) : title}
      </StyledTitleSection>
      {(isVideoConferencingEnabled || location) &&
        <StyledLocationSection>
          <StyledFontAwesomeIcon icon={faMapMarkerAlt} />
          <div>
            {isVideoConferencingEnabled && account?.video_conferencing_type ?
              (location ?
                <OutboundLink
                  externalLinkIcon={false}
                  href={location}
                  label="Calendar Event Description Video Conferencing Link"
                >
                  {location}
                </OutboundLink> :
                <StyledVideoConferencingLogo>
                  <Avatar
                    alt={account.video_conferencing_type}
                    className={`avatar-${account.video_conferencing_type}`}
                    img={videoConferencingLogos[account.video_conferencing_type]}
                    size="small"
                    type="company"
                  />
                  We will add a {videoConferencingLabels[account.video_conferencing_type]} link when the schedule is created.
                </StyledVideoConferencingLogo>
              ) :
              location
            }
          </div>
        </StyledLocationSection>
      }
      {roomId &&
        <StyledSection>
          <StyledFontAwesomeIcon icon={faDoorOpen} />
          {room?.name}
        </StyledSection>
      }
      {description && description !== '\n' &&
        <StyledDescriptionSection>
          <StyledFontAwesomeIcon icon={faAlignLeft} />
          {typeof description === 'string' ?
            <StyledDescriptionText dangerouslySetInnerHTML={{ __html: description }} /> :
            <StyledDescriptionText>{description}</StyledDescriptionText>
          }
        </StyledDescriptionSection>
      }
      {guests &&
        <StyledSection>
          <StyledFontAwesomeIcon icon={faUserFriends} />
          <div>
            <div>
              <b>{guests.length}</b> guest{guests.length !== 1 && 's'}
            </div>
            {guests.map((guest, i) => (
              <ListItem
                key={`guest-${i}`}
                label={guest.name || (guest.id ? users[guest.id].name || users[guest.id].email : '')}
                leftIcon={
                  <Avatar
                    alt={innerText(guest.name) || (guest.id ? users[guest.id].name || users[guest.id].email : '')}
                    showOptionalBadge={guest.optional}
                    size="large"
                    status={guest.rsvp && rsvpAvatarStatus[guest.rsvp] ? rsvpAvatarStatus[guest.rsvp].status : undefined}
                    tooltipHelperText={guest.rsvp && rsvpAvatarStatus[guest.rsvp] ? rsvpAvatarStatus[guest.rsvp].tooltipHelperText : undefined}
                    type={guest.id ? 'user' : 'candidate'}
                    userId={guest.id}
                  />
                }
                secondaryText={guest.description}
              />
            ))}
          </div>
        </StyledSection>
      }
      {showAs && (
        <StyledSingleLineSection>
          <StyledFontAwesomeIcon data-for={`${id}-show-as-icon-tooltip`} data-tip icon={faBriefcase} />
          <Tooltip
            helperText="Whether the interviewer is free or busy during this event"
            id={`${id}-show-as-icon-tooltip`}
            value="Event Transparency"
          />
          {startCase(showAs)}
        </StyledSingleLineSection>
      )}
    </StyledCard>
  );
};

export default CalendarEventPreview;
