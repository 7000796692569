import QueryString from 'qs';

import Config from '../config';
import { serialize } from './form-data';
import { correctPath } from './gem';

export class InterviewPlannerError extends Error {
  // The InterviewPlanner error code.
  public code: string;
  // The response status code.
  public status: number;

  constructor (message: string, code: string, status: number) {
    super(message);
    this.code = code;
    this.status = status;
    this.name = 'InterviewPlannerError';
  }
}

interface ErrorResponse {
  error: {
    message: string;
  };
}

class InterviewPlanner {
  private uri: string;

  constructor () {
    this.uri = correctPath('/api');
  }

  async checkStatus (isChunked: boolean, response: Response) {
    let resp: any;
    if (isChunked) {
      // For chunked responses, we keep the connection alive by sending . down the wire until we're ready to send the
      // full JSON response. So we strip all leading . to get a valid JSON string that we can parse.
      const text = (await response.text()).replace(/^\.*/g, '');
      resp = JSON.parse(text);
    } else {
      resp = await response.json();
    }
    if (isChunked ? !resp.error : response.status >= 200 && response.status < 300) {
      return resp;
    }
    const errorMessage: string = resp.code === 'multi_error' ?
      resp.errors.map((e: ErrorResponse) => e.error.message).join('\n') :
      resp.error.message;
    throw new InterviewPlannerError(errorMessage, resp.code || resp.error?.code, resp.error?.status_code || response.status);
  }

  request<T, U = any, V = any> (method: string, endpoint: string, payload?: U, query?: V, isMultiPartForm: boolean = false, isChunked: boolean = false): Promise<T> {
    const headers: Record<string, string> = isMultiPartForm ? {} : {
      'content-type': 'application/json; charset=utf-8',
      'x-version': Config.VERSION,
    };

    let body = undefined;
    if (payload) {
      if (isMultiPartForm) {
        body = serialize(payload, { indices: true });
      } else {
        body = JSON.stringify(payload);
      }
    }

    let uri = `${this.uri}${endpoint}`;
    if (query) {
      const queryString = QueryString.stringify(query, { indices: false });
      uri = `${uri}?${queryString}`;
    }

    // Create a new AbortController instance for this request so we can cancel
    // it if need be.
    const controller = new AbortController();

    const promise = fetch(uri, {
      method,
      headers,
      body,
      signal: controller.signal,
    })
    .then((response) => this.checkStatus(isChunked, response));

    // I'm going to leave this commented out for now because it's unclear if we
    // want to actually cancel these requests. This is because the data isn't
    // completely useless. If this query finishes, it'll be kept in the React
    // Query state and will be reused if the user goes back to that page. I also
    // don't think the backend really handles canceling requests very well, so
    // it's not like we're saving any server compute power. But if we do want to
    // bring it in, just uncomment this line.

    // To cancel requests, React Query calls the `promise.cancel` method.
    // promise.cancel = () => controller.abort();

    return promise;
  }
}

export default new InterviewPlanner();
