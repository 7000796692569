export function isRunningUnderGem (): boolean {
  return window.location.pathname.startsWith('/scheduling');
}

export function correctPath (path: string): string {
  const PREFIX = isRunningUnderGem() ? '/scheduling' : '';
  return PREFIX + path;
}

export function fullGemPath (path: string): string {
  let origin = `${window.location.origin}/scheduling`;
  if (window.location.hostname === 'staging.interviewplanner.com') {
    origin = 'https://beta.gem.com/scheduling';
  } else if (window.location.hostname === 'interviewplanner.com' || window.location.hostname === 'www.interviewplanner.com') {
    origin = 'https://www.gem.com/scheduling';
  } else if (window.location.hostname === 'localhost') {
    origin = window.location.origin;
  }
  return origin + path;
}
