import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Flash from '../../library/utils/Flash';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import Section from '../../library/layout/Section';
import Table from '../../library/data-display/Table';
import { ATS, atsLabels } from '../../../types';
import { useAccount } from '../../../hooks/queries/accounts';
import { useOffices } from '../../../hooks/queries/offices';

import type { Office } from '../../../types';
import type { TableSchema } from '../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const atsHelpLinks: Record<ATS, string> = {
  [ATS.Gem]: '', // TODO
  [ATS.Greenhouse]: 'https://support.greenhouse.io/hc/en-us/articles/115002226426',
  [ATS.Lever]: 'https://help.lever.co/hc/en-us/articles/115001616506-How-do-I-administer-Departments-Teams-Locations-and-Work-types-',
  [ATS.SuccessFactors]: '', // TODO
};

const atsOffice: Record<ATS, string> = {
  [ATS.Gem]: 'location',
  [ATS.Greenhouse]: 'office',
  [ATS.Lever]: 'location',
  [ATS.SuccessFactors]: 'location',
};

const columns: TableSchema<Office> = [{
  header: 'Name',
  displayValue: ({ name }) => name,
}, {
  header: 'Address',
  displayValue: ({ address }) => address,
}, {
  header: 'Directions',
  displayValue: ({ instructions }) => instructions,
}];

const CompanyOfficesSection = () => {
  const { data: account } = useAccount();

  const {
    data: offices,
    error,
    isSuccess,
  } = useOffices({}, {
    enabled: Boolean(account!.ats_type),
  });

  return (
    <Section
      title="Offices"
    >
      <Flash
        message={<span>Connect to your ATS <Link to={correctPath('/app/integrations')}>here</Link> so that we can sync your offices.</span>}
        showFlash={!account!.ats_type}
        type="info"
      />
      {account!.ats_type && (
        <>
          <Flash
            message={
              <span>
                You have not created any {atsOffice[account!.ats_type]}s in {atsLabels[account!.ats_type]}.&nbsp;
                {atsHelpLinks[account!.ats_type] && (
                  <a
                    href={atsHelpLinks[account!.ats_type]}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn how to add {['a', 'e', 'i', 'o', 'u'].includes(atsOffice[account!.ats_type][0]) ? 'an' : 'a'} {atsOffice[account!.ats_type]}. <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                )}
              </span>
            }
            showFlash={account!.ats_type && isSuccess && offices?.offices?.length === 0}
            type="info"
          />
          <Flash
            message="The ability to manage addresses and travel directions is coming soon!"
            showFlash={Boolean(offices?.offices?.length && offices?.offices?.length > 0)}
            type="info"
          />
          <Flash
            message={error?.message}
            showFlash={Boolean(error)}
            type="danger"
          />
          {!offices ? (
            <LoadingSpinner />
          ) : (
            <Table
              data={offices.offices}
              dataDescriptor="offices"
              layout="vertical"
              pageSize={offices.offices.length}
              schema={columns}
              showResultsCount
              totalCount={offices.offices.length}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default CompanyOfficesSection;
