import { init } from '@sentry/react';

import Config from '../config';

init({
  dsn: Config.SENTRY_DSN,
  enabled: Config.ENVIRONMENT !== 'development',
  environment: Config.ENVIRONMENT,
  release: Config.VERSION,
  ignoreErrors: [
    // Slate errors. They don't seem to manifest any user-facing issues, so it's just noise.
    /Cannot get the end point in the node/,
    /Cannot get the start point in the node/,
    /Cannot resolve a DOM point from Slate point/,
    // Transient network errors. There's nothing we can do about them.
    /A network error occurred/,
    /Failed to fetch/,
    /Load failed/,
  ],
});
