import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { orderBy, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import CalendarEventTemplateForm from '../../../../library/inputs/CalendarEventTemplateForm';
import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import EmailTemplateForm from '../../../../library/inputs/EmailTemplateForm';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import SchedulingCalendarSelectInput from '../../../../library/inputs/SchedulingCalendarSelectInput';
import { Directory, liveCodingLabels } from '../../../../../types';
import { Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';
import { constructScheduleTokensPayload, useSelfSchedulingApplications, pathForSelfScheduling } from './helpers';
import { useCalendars } from '../../../../../hooks/queries/calendars';
import { useSession } from '../../../../../hooks/use-session';
import { useTokens } from '../../../../../hooks/queries/tokens';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { Token } from '../../../../../types';
import pluralize from 'libraries/pluralize';

const pendingPreviewMessage = 'This token will be filled in when the candidate schedules their interview.';

const EventsAndEmailsStep = () => {
  const data = useSelfSchedulingApplications();
  const { isBulk, applicationIDs } = data;
  const arbitraryApplication = data.arbitraryApplication!;
  const stage = arbitraryApplication.current_stage;

  const { account, currentUser } = useSession();
  const { data: calendars, isLoading: calendarsIsLoading } = useCalendars();
  const users = useUsersMap({ archived: true });

  const {
    completedStep,
    selfSchedulingLink,
    setCompletedStep,
    setSelfSchedulingLink,
  } = useNewSelfSchedulingLink();

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  const [error, setError] = useState<Error | null>(null);
  // calendar event
  const [schedulingCalendar, setSchedulingCalendar] = useState(selfSchedulingLink.scheduling_calendar_email || '');
  const [candidateSchedulingCalendar, setCandidateSchedulingCalendar] = useState(selfSchedulingLink.candidate_scheduling_calendar_email || '');
  const [candidateCalendarEventTemplateId, setCandidateCalendarEventTemplateId] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.id);
  const [candidateCalendarEventTemplateTitle, setCandidateCalendarEventTemplateTitle] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.title || '');
  const [candidateCalendarEventTemplateDescription, setCandidateCalendarEventTemplateDescription] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.description || '');
  const [candidateCalendarEventTemplateLocation, setCandidateCalendarEventTemplateLocation] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.location);
  const [candidateCalendarEventTemplateAdditionalAttendees, setCandidateCalendarEventTemplateAdditionalAttendees] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
  const [candidateCalendarEventTemplateAdditionalOptionalAttendees, setCandidateCalendarEventTemplateAdditionalOptionalAttendees] = useState(selfSchedulingLink.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
  const [calendarErrorTokens, setCalendarErrorTokens] = useState<Token[]>([]);
  const [calendarIncludedTokens, setCalendarIncludedTokens] = useState<Token[]>([]);
  // confirmation email
  const [isConfirmationEmailEnabled, setIsConfirmationEmailEnabled] = useState(canSendEmail && Boolean(selfSchedulingLink.schedule_template.confirmation_email_template));
  const [emailTemplate, setEmailTemplate] = useState(selfSchedulingLink.schedule_template.confirmation_email_template);
  const [emailErrorTokens, setEmailErrorTokens] = useState<Token[]>([]);
  const [emailHasRequiredTokens, setEmailHasRequiredTokens] = useState(false);
  // other
  const [createHiringChannel, setCreateHiringChannel] = useState(selfSchedulingLink.schedule_template.create_hiring_channel);
  const [markInterviewerEventsAsPrivate, setMarkInterviewerEventsAsPrivate] = useState(selfSchedulingLink.schedule_template.mark_interviewer_events_as_private);
  const [markCandidateEventsAsPrivate, setMarkCandidateEventsAsPrivate] = useState(selfSchedulingLink.schedule_template.mark_candidate_events_as_private);

  const {
    data: calendarTokens,
    error: calendarTokensError,
  } = useTokens({
    type: 'candidate_calendar_event',
    schedule: constructScheduleTokensPayload(arbitraryApplication, users, selfSchedulingLink),
  }, { enabled: completedStep >= Step.EventsAndEmails });

  const {
    data: emailTokens,
    error: emailTokensError,
  } = useTokens({
    type: 'confirmation_email',
    schedule: constructScheduleTokensPayload(arbitraryApplication, users, selfSchedulingLink),
  }, { enabled: completedStep >= Step.EventsAndEmails });

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  const errorTokens = useMemo(() => {
    return orderBy([
      ...calendarErrorTokens,
      ...emailErrorTokens,
    ]);
  }, [calendarErrorTokens, emailErrorTokens]);
  const descriptionHasAgenda = useMemo(() => calendarIncludedTokens.some((name) => name.startsWith('Schedule.Agenda')), [calendarIncludedTokens]);

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option?.value || '');
  const handleCandidateSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setCandidateSchedulingCalendar(option?.value || '');
  const handleCreateHiringChannelChange = (e: ChangeEvent<HTMLInputElement>) => setCreateHiringChannel(e.target.checked);
  const handleMarkInterviewerEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkInterviewerEventsAsPrivate(e.target.checked);
  const handleMarkCandidateEventsAsPrivateChange = (e: ChangeEvent<HTMLInputElement>) => setMarkCandidateEventsAsPrivate(e.target.checked);

  const handleNext = () => {
    setError(null);

    // If there are any live coding links, make sure the agenda is being sent in some form. Exabeam doesn't want the
    // links in their agendas, so we exclude this check for them.
    const isExabeam = account!.id === '8fac4b62-daf0-44ea-a357-33997a854c43' || account!.name?.toLowerCase().includes('exabeam');
    const hasLiveCodingLinks = Boolean(account?.live_coding_type && selfSchedulingLink.stage_interview.interview_template.live_coding_enabled);
    if (!isExabeam && hasLiveCodingLinks && !descriptionHasAgenda && (!isConfirmationEmailEnabled || !emailHasRequiredTokens)) {
      setError(new Error(`The calendar description or confirmation email must include a Schedule.Agenda token since that's where the ${liveCodingLabels[account!.live_coding_type!]} links will be included.`));
      return false;
    }

    setSelfSchedulingLink((prev) => ({
      ...prev,
      scheduling_calendar_email: schedulingCalendar,
      candidate_scheduling_calendar_email: candidateSchedulingCalendar,
      schedule_template: {
        ...prev.schedule_template,
        candidate_calendar_event_template: {
          id: candidateCalendarEventTemplateId,
          title: candidateCalendarEventTemplateTitle,
          description: candidateCalendarEventTemplateDescription,
          location: candidateCalendarEventTemplateLocation,
          additional_attendees: candidateCalendarEventTemplateAdditionalAttendees,
          additional_optional_attendees: candidateCalendarEventTemplateAdditionalOptionalAttendees,
        },
        confirmation_email_template: isConfirmationEmailEnabled ? {
          ...pick(emailTemplate!, [
            'id',
            'name',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
          name: emailTemplate!.name || 'Confirmation Email',
        } : undefined,
        create_hiring_channel: createHiringChannel,
        mark_interviewer_events_as_private: markInterviewerEventsAsPrivate,
        mark_candidate_events_as_private: markCandidateEventsAsPrivate,
      },
    }));
    setCompletedStep(Step.EventsAndEmails + 1);
  };

  if (completedStep < Step.EventsAndEmails) {
    return <Redirect to={pathForSelfScheduling(isBulk, applicationIDs, 'preferences')} />;
  }

  const isVideoConferencingEnabled = selfSchedulingLink.schedule_template.video_conferencing_enabled;

  return (
    <Breadcrumb
      data={{
        title: '2. Events & Emails',
        pathname: pathForSelfScheduling(isBulk, applicationIDs, 'events-and-emails'),
      }}
    >
      <MultiStepFormStep
        backLocation={pathForSelfScheduling(isBulk, applicationIDs, 'preferences')}
        className="form-step-calendar-events"
        nextButtonIsDisabled={errorTokens.length > 0 || calendarsIsLoading || Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar]) || Boolean(candidateSchedulingCalendar && calendars && !calendars.calendars[candidateSchedulingCalendar])}
        nextButtonValue={calendarsIsLoading ? 'Loading...' : 'Review'}
        nextLocation={pathForSelfScheduling(isBulk, applicationIDs, 'review')}
        onNext={handleNext}
      >
        <Helmet>
          <title>2. Events &amp; Emails | Send {isBulk ? `${applicationIDs.length} ${pluralize('candidate', applicationIDs.length)}` : (arbitraryApplication.candidate.name || 'Unknown')} a link to schedule {stage?.name} | Gem Scheduling</title>
        </Helmet>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={calendarTokensError?.message}
          showFlash={Boolean(calendarTokensError)}
          type="danger"
        />
        <Flash
          message={emailTokensError?.message}
          showFlash={Boolean(emailTokensError)}
          type="danger"
        />
        <Flash
          message="You don't have access to the currently set scheduling calendar. Please select a different one or ask someone who does have access to it to share it with you."
          showFlash={Boolean(schedulingCalendar && calendars && !calendars.calendars[schedulingCalendar])}
          type="danger"
        />
        <Flash
          message="You don't have access to the currently set scheduling calendar for candidate events. Please select a different one or ask someone who does have access to it to share it with you."
          showFlash={Boolean(candidateSchedulingCalendar && calendars && !calendars.calendars[candidateSchedulingCalendar])}
          type="danger"
        />
        <Flash
          message={<>Fill out the calendar event and confirmation email that we will send to the candidate <b>after they book their interview</b>. You will be able to edit these messages after you create the self-booking link.</>}
          showFlash
          type="info"
        />
        <ErrorTokenFlash errorTokens={errorTokens} />
        <SchedulingCalendarSelectInput
          helperText={<><FontAwesomeIcon className="helper-text-warning-icon" icon={faExclamationTriangle} /> This cannot be changed after you create the schedule.</>}
          isRequired
          onChange={handleSchedulingCalendarChange}
          schedulingCalendar={schedulingCalendar}
        />
        <SchedulingCalendarSelectInput
          helperText={<><FontAwesomeIcon className="helper-text-warning-icon" icon={faExclamationTriangle} /> This cannot be changed after you create the schedule.</>}
          isClearable
          label="Scheduling Calendar for Candidate Events"
          onChange={handleCandidateSchedulingCalendarChange}
          placeholder="Leave blank to use the primary scheduling calendar"
          schedulingCalendar={candidateSchedulingCalendar}
        />
        {account?.directory_type === Directory.Google && (
          <>
            <CheckboxInput
              helperText="Recommended when scheduling a sensitive candidate, e.g. an executive or existing employee."
              isChecked={markInterviewerEventsAsPrivate}
              label="Make interviewer calendar events private."
              onChange={handleMarkInterviewerEventsAsPrivateChange}
            />
            <CheckboxInput
              helperText="Recommended when scheduling a sensitive candidate or when sending to their work email."
              isChecked={markCandidateEventsAsPrivate}
              label="Make candidate calendar event private."
              onChange={handleMarkCandidateEventsAsPrivateChange}
            />
          </>
        )}
        <CalendarEventTemplateForm
          additionalAttendees={candidateCalendarEventTemplateAdditionalAttendees}
          additionalOptionalAttendees={candidateCalendarEventTemplateAdditionalOptionalAttendees}
          description={candidateCalendarEventTemplateDescription}
          id={candidateCalendarEventTemplateId}
          isVideoConferencingEnabled={isVideoConferencingEnabled}
          location={candidateCalendarEventTemplateLocation}
          locationHelperText={isVideoConferencingEnabled ? 'We will add a video conferencing link when the schedule is created.' : undefined}
          pendingPreviewMessage={pendingPreviewMessage}
          setAdditionalAttendees={setCandidateCalendarEventTemplateAdditionalAttendees}
          setAdditionalOptionalAttendees={setCandidateCalendarEventTemplateAdditionalOptionalAttendees}
          setDescription={setCandidateCalendarEventTemplateDescription}
          setErrorTokens={setCalendarErrorTokens}
          setId={setCandidateCalendarEventTemplateId}
          setIncludedTokens={setCalendarIncludedTokens}
          setLocation={setCandidateCalendarEventTemplateLocation}
          setTitle={setCandidateCalendarEventTemplateTitle}
          title={candidateCalendarEventTemplateTitle}
          tokens={calendarTokens}
          type="candidate_calendar_event"
        />
        <EmailTemplateForm
          additionalRequiredTokenPrefixes={descriptionHasAgenda ? [] : ['Schedule.Agenda']}
          emailTemplate={emailTemplate}
          enabledCheckboxLabel="Send confirmation email to candidate."
          isDisabled={!canSendEmail}
          isEnabled={isConfirmationEmailEnabled}
          pendingPreviewMessage={pendingPreviewMessage}
          setEmailTemplate={setEmailTemplate}
          setErrorTokens={setEmailErrorTokens}
          setHasRequiredTokens={setEmailHasRequiredTokens}
          setIsEnabled={setIsConfirmationEmailEnabled}
          tokens={emailTokens}
          type="confirmation_email"
        />
        {account?.chat_type && (
          <CheckboxInput
            helperText="We will create a private hiring channel for the candidate and invite all current and past interviewers once the candidate schedules their interview."
            isChecked={createHiringChannel}
            label="Create a hiring channel."
            onChange={handleCreateHiringChannelChange}
          />
        )}
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default EventsAndEmailsStep;
