import innerText from 'react-innertext';
import { kebabCase } from 'lodash';
import { useMemo } from 'react';

import LoadingSpinner from '../../utils/LoadingSpinner';
import TableCell from './TableCell';
import TableExpandingRow from './TableExpandingRow';
import TableHeader from './TableHeader';

import type { ButtonSize } from '../../inputs/Button';
import type { ChangeEvent, ReactNode } from 'react';
import type { FilteredTableSchema, TableLayout, TableSchema, TableSchemaItem, WithId } from './types';

// Re-export public types.
export { TableLayout } from './types';
export type { TableSchema } from './types';

interface Props<T> {
  additionalHeaderElements?: ReactNode;
  prefixedAdditionalHeaderElements?: ReactNode;
  className?: string;
  collapseRowTooltipText?: string;
  data: T[];
  dataDescriptor?: string;
  displayExpandedContent?: (item: T, index: number) => ReactNode;
  displayRowArrowLabel?: (item: T) => ReactNode;
  expandRowTooltipText?: string;
  getRowClassName?: (item: T) => string;
  getRowIsExpanded?: (item: T) => boolean;
  getRowIsExpanding?: (item: T) => boolean;
  getRowLink?: (item: T) => string | undefined;
  isArchivedDisabled?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  isPaginated?: boolean;
  layout: `${TableLayout}`;
  onPageNumberChange?: (pageNumber: number) => void;
  onShowArchivedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber?: number;
  pageSize?: number;
  rowArrowButtonSize?: `${ButtonSize}`;
  schema: TableSchema<T>;
  showArchived?: boolean;
  showArchivedLabel?: string;
  showGrid?: boolean;
  showHeaderRow?: boolean;
  showResultsCount?: boolean;
  totalCount?: number;
}

const Table = <T extends any>({
  additionalHeaderElements,
  prefixedAdditionalHeaderElements,
  className,
  collapseRowTooltipText,
  data,
  dataDescriptor,
  displayExpandedContent,
  displayRowArrowLabel,
  expandRowTooltipText,
  getRowClassName,
  getRowIsExpanded,
  getRowIsExpanding,
  getRowLink,
  isArchivedDisabled = false,
  isEditing = false,
  isLoading = false,
  isPaginated = false,
  layout,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  pageSize,
  rowArrowButtonSize,
  schema,
  showArchived,
  showArchivedLabel,
  showGrid = false,
  showHeaderRow = true,
  showResultsCount,
  totalCount,
}: Props<T>) => {
  const isAnyExpanding = useMemo(() => {
    if (!displayExpandedContent) {
      return false;
    }
    if (!getRowIsExpanding) {
      return Boolean(displayExpandedContent);
    }
    return data.some((item) => getRowIsExpanding(item));
  }, [getRowIsExpanding, displayExpandedContent, data]);

  const filteredSchema = useMemo<FilteredTableSchema<T>>(() => {
    return schema.filter((item): item is TableSchemaItem<T> => Boolean(item));
  }, [schema]);

  return (
    <table className={`table table-${layout}${showGrid ? ' grid' : ''}${className ? ` ${className}` : ''}`}>
      {layout === 'vertical' && showHeaderRow &&
        <thead>
          {(isPaginated || showResultsCount) &&
            <TableHeader
              additionalElements={additionalHeaderElements}
              dataDescriptor={dataDescriptor}
              isDisabled={isArchivedDisabled}
              isLoading={isLoading}
              numColumns={isAnyExpanding ? filteredSchema.length + 1 : filteredSchema.length}
              onPageNumberChange={onPageNumberChange}
              onShowArchivedChange={onShowArchivedChange}
              pageNumber={pageNumber}
              pageSize={pageSize}
              prefixedAdditionalElements={prefixedAdditionalHeaderElements}
              showArchived={showArchived}
              showArchivedLabel={showArchivedLabel}
              totalCount={totalCount}
            />
          }
          <tr>
            {filteredSchema.map((property, i) => (
              <th key={`column-${kebabCase(property.key || innerText(property.header)) || i}`} scope="col">
                {property.header}
              </th>
            ))}
            {isAnyExpanding && <th key="column-expand-action" scope="col" />}
          </tr>
        </thead>
      }
      {isLoading ?
        <tbody>
          <tr>
            <td colSpan={filteredSchema.length}>
              <div className="spinner-container">
                <LoadingSpinner />
              </div>
            </td>
          </tr>
        </tbody> :
        <tbody>
          {layout === 'vertical' ?
            data.map((item, itemIndex) => {
              const rowLink = getRowLink?.(item);
              const rowKey = `row-${(item as WithId).id || itemIndex}`;

              const row = (
                <tr
                  className={`${rowLink ? 'row-link' : ''}${getRowClassName ? ` ${getRowClassName(item)}` : ''}`}
                  key={rowKey}
                >
                  {filteredSchema.map((property, propertyIndex) => (
                    <TableCell
                      className={property.getCellClassName?.(item, itemIndex)}
                      hasLinkStyleOnHover={property.hasLinkStyleOnHover}
                      id={property.id?.(item, itemIndex)}
                      isClickable={property.isClickable}
                      key={`cell-${kebabCase(property.key || innerText(property.header)) || propertyIndex}`}
                      link={rowLink}
                      popoverContent={property.displayPopoverContent?.(item, itemIndex)}
                      value={isEditing && property.displayEditValue ? property.displayEditValue(item, itemIndex) : property.displayValue?.(item, itemIndex)}
                    />
                  ))}
                </tr>
              );
              const isExpanding = getRowIsExpanding ? Boolean(displayExpandedContent) && getRowIsExpanding(item) : Boolean(displayExpandedContent);
              const expandedContent = isExpanding && displayExpandedContent?.(item, itemIndex);

              return (isAnyExpanding ?
                <TableExpandingRow
                  arrowButtonSize={rowArrowButtonSize}
                  arrowLabel={displayRowArrowLabel?.(item)}
                  className={getRowClassName?.(item)}
                  collapseTooltipText={collapseRowTooltipText}
                  expandTooltipText={expandRowTooltipText}
                  expandedContent={expandedContent || null}
                  isExpanded={getRowIsExpanded?.(item)}
                  key={rowKey}
                  numColumns={filteredSchema.length}
                  row={row}
                /> :
                row
              );
            }) :
            filteredSchema.map((property, propertyIndex) => (
              <tr key={`row-${kebabCase(innerText(property.header)) || propertyIndex}`}>
                <th scope="row">
                  {property.header}
                </th>
                {data.map((item, itemIndex) => (
                  <td key={`cell-${(item as WithId).id || itemIndex}`}>
                    {isEditing && property.displayEditValue ? property.displayEditValue(item, itemIndex) : property.displayValue?.(item, itemIndex)}
                  </td>
                ))}
              </tr>
            ))
          }
        </tbody>
      }
    </table>
  );
};

export default Table;
