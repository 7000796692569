import type { Application } from '../../../../types';

export const checkApplicationsForDifferentStages = (applications: Map<string, Application>): boolean => {
  let appsHaveDifferentStages = false;
  if (applications.size > 1) {
    const iter = applications.entries();
    const [_, firstApp] = iter.next().value;
    const firstStage = firstApp.current_stage_id;
    for (const [_, app] of iter) {
      if (app.current_stage_id !== firstStage) {
        appsHaveDifferentStages = true;
        break;
      }
    }
  }

  return appsHaveDifferentStages;
};
