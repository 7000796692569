import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { faCircleNotch, faPen } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';

import Button from '../inputs/Button';
import NewFeatureLabel from '../utils/NewFeatureLabel';

import type { FormEvent, MouseEvent, ReactNode } from 'react';

interface Props {
  additionalHeaderActions?: ReactNode;
  /**
   * Use this to specifically place the additional header actions.
   * 0: Before the edit button.
   * 1: Before the cancel button.
   * 2: Between the cancel and save buttons (this is the default).
   * 3: After the save button, but before the saving button.
   * 4: After the saving button.
   */
  additionalHeaderActionsIndex?: number;
  children: ReactNode;
  className?: string;
  headerActions?: ReactNode;
  isEditable?: boolean;
  isEditing?: boolean;
  isNew?: boolean;
  isSaveButtonDisabled?: boolean;
  isSaving?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onSave?: () => void;
  saveButtonTooltip?: JSX.Element;
  showSaveButton?: boolean;
  title: string | JSX.Element;
}

const Section = ({
  additionalHeaderActions,
  additionalHeaderActionsIndex = 2,
  children,
  className,
  headerActions,
  isEditable = false,
  isEditing,
  isNew = false,
  isSaveButtonDisabled = false,
  isSaving,
  onCancel,
  saveButtonTooltip,
  onEdit,
  onSave,
  showSaveButton = true,
  title,
}: Props) => {
  const classes = `section${className ? ` ${className}` : ''}`;

  if (!isEditable) {
    return (
      <div className={classes} id={typeof title === 'string' ? kebabCase(title) : undefined}>
        <div className="section-header">
          <div className="section-title">
            <h2>{title}</h2>
            {isNew && <NewFeatureLabel />}
          </div>
          {headerActions &&
            <div className="section-header-actions">
              {headerActions}
              {additionalHeaderActions}
            </div>
          }
        </div>
        <div className="section-content">
          {children}
        </div>
      </div>
    );
  }

  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onEdit?.();
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCancel?.();
  };

  const handleSave = (e: FormEvent) => {
    e.preventDefault();
    onSave?.();
  };

  const buttons = {
    edit: <Button
      color="gem-outline"
      iconLeft={<FontAwesomeIcon icon={faPen} />}
      key="edit"
      onClick={handleEdit}
      size="small"
      value="Edit"
    />,
    cancel: <Button
      color="no-outline"
      key="cancel"
      onClick={handleCancel}
      size="small"
      type="reset"
      value="Cancel"
    />,
    save: <Button
      color="gem-blue"
      isDisabled={isSaveButtonDisabled}
      key="save"
      size="small"
      tooltip={saveButtonTooltip}
      type="submit"
      value="Save"
    />,
    saving: <Button
      color="gem-blue"
      iconLeft={<FontAwesomeIcon icon={faCircleNotch} spin />}
      isDisabled
      key="saving"
      size="small"
      value="Saving..."
    />,
  };

  const editingHeaderActions: ReactNode[] = [
    !isEditing && !isSaving && buttons.edit,
    isEditing && buttons.cancel,
    isEditing && !isSaving && showSaveButton && buttons.save,
    isSaving && showSaveButton && buttons.saving,
  ];
  editingHeaderActions.splice(additionalHeaderActionsIndex, 0, additionalHeaderActions);
  // .filter((node): node is ReactNode => Boolean(node));

  return (
    <form
      className={classes}
      data-cy={typeof title === 'string' ? `${kebabCase(title)}-section` : undefined}
      id={typeof title === 'string' ? kebabCase(title) : undefined}
      onSubmit={handleSave}
    >
      <div className="section-header">
        <div className="section-title">
          <h2>{title}</h2>
          {isNew && <NewFeatureLabel />}
        </div>
        <div className="section-header-actions">
          {editingHeaderActions.map((node, i) => <Fragment key={i}>{node}</Fragment>)}
        </div>
      </div>
      <div className="section-content">
        {children}
      </div>
    </form>
  );
};

export default Section;
