import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { capitalize, find, startCase } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from 'components/library/inputs/Button';
import SelectInput from 'components/library/inputs/SelectInput';
import TextInput from 'components/library/inputs/TextInput';
import Section from 'components/library/layout/Section';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { signUpParams } from 'hooks/queries/auth';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';
import useSyncStateWithQuery from 'hooks/use-sync-state-with-query';
import { ATS } from 'types';
import ManualATSSyncButton from './ManualATSSyncButton';

import type { ChangeEvent } from 'react';
import type { ErrorLike } from './types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../library/inputs/SelectInput/types';

const ERROR_CODES: Record<string, JSX.Element> = {
  // this happens when the user goes to /api/auth/lever/redirect directly
  ats_no_code: <span>There was an error when redirecting you. Try connecting again, and if it continues to happen, <Link to="/contact">let us know</Link>.</span>,
};

const atsOptions: Option<`${ATS}`>[] = [
  { value: ATS.Gem, label: 'Gem' },
  { value: ATS.Greenhouse, label: 'Greenhouse' },
  { value: ATS.Lever, label: 'Lever' },
];

const atsHelpLinks: Record<ATS, string> = {
  [ATS.Gem]: '', // TODO
  [ATS.Greenhouse]: 'https://support.gem.com/hc/en-us/articles/23491781210007-How-do-I-configure-my-Greenhouse-API-key',
  [ATS.Lever]: 'https://support.gem.com/hc/en-us/articles/23491694906903-How-do-I-connect-my-Lever-account',
  [ATS.SuccessFactors]: '', // TODO
};

const IntegrationsATSSection = () => {
  const queryClient = useQueryClient();

  const { data } = useAccount();
  const account = data!;
  const hasAtsIntegration = Boolean(account.ats_type);

  const [, queryError] = useSyncStateWithQuery('error', '');
  const [, querySuccess] = useSyncStateWithQuery('ats_success', '');

  const [error, setError] = useState<ErrorLike | null>(ERROR_CODES[queryError] ? { message: ERROR_CODES[queryError] } : null);
  const [isFetching, setIsFetching] = useState(false);
  const [atsType, setAtsType] = useState<`${ATS}` | ''>(account.ats_type || '');
  const [greenhouseSubdomain, setGreenhouseSubdomain] = useState(account.greenhouse_subdomain || '');
  const [atsApiKey, setAtsApiKey] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [manualSyncStarted, setManualSyncStarted] = useState(false);

  const updateAccountMutation = useUpdateAccount();

  const apiKeyRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAtsType(account.ats_type || '');
    setGreenhouseSubdomain(account.greenhouse_subdomain || '');
    setIsEditing(!account.ats_type);
  }, [account.ats_type]);

  useEffect(() => {
    if (isEditing && apiKeyRef.current) {
      apiKeyRef.current?.focus();
    }
  }, [isEditing]);

  const options = useMemo(() => {
    if (account?.ats_type === ATS.SuccessFactors) {
      return [
        ...atsOptions,
        { value: ATS.SuccessFactors, label: 'SuccessFactors' },
      ];
    }
    return atsOptions;
  }, [account?.ats_type]);

  const handleAtsTypeChange = (option: OnChangeValue<Option<`${ATS}`>, false>) => setAtsType(option ? option.value : '');
  const handleGreenhouseSubdomainChange = (e: ChangeEvent<HTMLInputElement>) => setGreenhouseSubdomain(e.target.value);
  const handleAtsApiKeyChange = (e: ChangeEvent<HTMLInputElement>) => setAtsApiKey(e.target.value);

  const handleConnectLever = async () => {
    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(ATS.Lever));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  const handleEdit = () => {
    setAtsApiKey('');
    setIsEditing(true);
  };

  const handleCancel = () => {
    setAtsType(account.ats_type || '');
    setGreenhouseSubdomain(account.greenhouse_subdomain || '');
    setIsEditing(false);
    setError(null);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    if (!atsType) {
      setError(new Error('Picking an ATS type is required.'));
      return;
    }

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          ats_type: atsType,
          greenhouse_subdomain: atsType === 'greenhouse' ? greenhouseSubdomain : undefined,
          ats_api_key: atsApiKey,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const isSaving = isFetching || updateAccountMutation.isLoading;
  const isSuccess = updateAccountMutation.isSuccess || Boolean(querySuccess);

  return (
    <Section
      additionalHeaderActions={!isEditing && account.ats_type && (
        <ManualATSSyncButton setManualSyncStarted={setManualSyncStarted} />
      )}
      additionalHeaderActionsIndex={0}
      isEditable={account.ats_type !== 'success_factors'}
      isEditing={isEditing}
      isSaving={isSaving}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      showSaveButton={atsType !== 'lever'}
      title="ATS"
    >
      <div className={classnames(['integrations-ats-form', atsType && `integrations-ats-form-${atsType}`])}>
        <Flash
          message="Connect to your ATS to start setting up jobs and scheduling candidates."
          showFlash={!hasAtsIntegration}
          type="info"
        />
        <Flash
          message={
            <span>
              Please ensure that your API key is correctly copied and has the&nbsp;
              <OutboundLink
                href="https://support.gem.com/hc/en-us/articles/23491781210007-How-do-I-configure-my-Greenhouse-API-key#api-key-permissions"
                label="Greenhouse Permissions Warning Flash"
              >
                necessary permissions
              </OutboundLink>
              . <b>We will be unable to sync information or schedule candidates</b> if this integration breaks.
            </span>
          }
          showFlash={hasAtsIntegration && account.ats_type === 'greenhouse' && atsType === 'greenhouse' && (isEditing || isSaving)}
          type="warning"
        />
        <Flash
          isDismissible
          message={`${capitalize(account.ats_type)} sync started!`}
          showFlash={manualSyncStarted}
          type="success"
        />
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={updateAccountMutation.error?.message}
          showFlash={updateAccountMutation.isError}
          type="danger"
        />
        <div className="form-container">
          <SelectInput
            className="input-ats-type"
            helperText={isEditing && atsType === 'lever' ?
              <OutboundLink
                href={atsHelpLinks[atsType]}
                label={`${startCase(atsType)} ATS Helper Text`}
              >
                Learn more about the {startCase(atsType)} integration.
              </OutboundLink> :
              null
            }
            isDisabled={!isEditing || isSaving}
            isRequired
            label="ATS Type"
            onChange={handleAtsTypeChange}
            options={options}
            placeholder="Select your ATS"
            value={find(options, ['value', atsType])}
          />
          {atsType === 'greenhouse' &&
            <TextInput
              className="input-subdomain"
              helperText={isEditing ?
                <span>
                  If you sign in at https://app2.greenhouse.io, app2 is your subdomain.&nbsp;
                  <OutboundLink
                    href="https://support.gem.com/hc/en-us/articles/23487293521175-What-s-my-Greenhouse-subdomain"
                    label="Greenhouse Subdomain Helper Text"
                  >
                    Learn more.
                  </OutboundLink>
                </span> :
                null
              }
              isDisabled={!isEditing || isSaving}
              isRequired
              label="Greenhouse Subdomain"
              onChange={handleGreenhouseSubdomainChange}
              placeholder="app2"
              value={greenhouseSubdomain}
            />
          }
          {isEditing && atsType === 'lever' &&
            <Button
              color="gem-blue"
              iconRight={isFetching ? <LoadingSpinner /> : undefined}
              isDisabled={isFetching}
              onClick={handleConnectLever}
              value={isFetching ? 'Connecting...' : `${hasAtsIntegration && account.ats_type === 'lever' ? 'Re-' : ''}Connect your account`}
            />
          }
          {atsType && atsType !== 'lever' && atsType !== 'success_factors' &&
            <TextInput
              className="input-ats-api-key"
              helperText={isEditing && atsHelpLinks[atsType] ?
                <OutboundLink
                  href={atsHelpLinks[atsType]}
                  label={`${startCase(atsType)} API Key Helper Text`}
                >
                  Where can I find mine?
                </OutboundLink> :
                null
              }
              isDisabled={!isEditing || isSaving}
              isRequired
              label="API Key"
              onChange={handleAtsApiKeyChange}
              ref={apiKeyRef}
              value={isEditing ? atsApiKey : '•'.repeat(20)}
            />
          }
        </div>
      </div>
    </Section>
  );
};

export default IntegrationsATSSection;
