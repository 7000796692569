import { Breadcrumb } from 'react-breadcrumbs';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import EmailSettingsWarningModal from '../EmailSettingsWarningModal';
import { EmailTemplateType } from 'types';
import EventsAndEmailsStep from './EventsAndEmailsStep';
import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import PreferencesStep from './PreferencesStep';
import ReviewStep from './ReviewStep';
import Section from '../../../../library/layout/Section';
import SendLinkStep from './SendLinkStep';
import BulkResultsStep from './BulkResultsStep';
import StepProgressBar from '../../../../library/navigation/StepProgressBar';
import { AtsHrefType, constructAtsHref } from '../../../../../libraries/candidates';
import { NewSelfSchedulingLinkProvider, Step, useNewSelfSchedulingLink, selfSchedulingLinkHasApplication } from './use-new-self-scheduling-link';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { defaultValuesForStageInterview, pathForSelfScheduling, useSelfSchedulingApplications } from './helpers';
import { useSession } from '../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../hooks/queries/users';
import pluralize from 'libraries/pluralize';

import type { ReactNode } from 'react';
import { correctPath } from 'libraries/gem';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Infotip from 'components/library/utils/Infotip';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateRequestSelfScheduling = () => {
  return (
    <NewSelfSchedulingLinkProvider>
      <CandidateRequestSelfSchedulingInner />
    </NewSelfSchedulingLinkProvider>
  );
};

export default CandidateRequestSelfScheduling;

const CandidateRequestSelfSchedulingInner = () => {
  const queryClient = useQueryClient();

  const { applicationIDs, applications, arbitraryApplication, isBulk, isLoading } = useSelfSchedulingApplications();

  // useApplication fetches additional fields on current_stage which
  // useApplications does not fetch, so it is necessary to use here
  const stage = arbitraryApplication?.current_stage;

  const { account, currentUser } = useSession();
  const users = useUsersMap({ archived: true });

  const [isError, setIsError] = useState(false);

  const {
    completedStep,
    selfSchedulingLink,
    setSelfSchedulingLink,
  } = useNewSelfSchedulingLink();

  useEffect(() => {
    if (!isLoading && !selfSchedulingLinkHasApplication(selfSchedulingLink) && !isEmpty(users)) {
      if (!arbitraryApplication) {
        setIsError(true);
        return;
      }
      if (!stage?.stage_interviews) {
        setIsError(true);
        return;
      }
      (async () => {
        // Find the first non-deleted, ATS-schedulable stage interview that has an interview
        // template or info imported from the ATS. If none of them match that
        // description, just pick the first stage interview.
        const eligibleStageInterviews = stage?.stage_interviews?.filter(({ ats_schedulable, deleted }) => !deleted && (ats_schedulable === null || ats_schedulable)) || [];
        let stageInterview = eligibleStageInterviews.find((interview) => {
          return Boolean(interview.interview_template || (interview.ats_interviewer_ids && interview.ats_interviewer_ids.length > 0) || interview.ats_duration_minutes);
        });
        if (!stageInterview) {
          stageInterview = eligibleStageInterviews[0];
        }
        const {
          businessHours,
          duration,
          feedbackFormId,
          interviewerTemplates,
          liveCodingEnabled,
          candidateFacingName,
          candidateFacingDetails,
        } = await defaultValuesForStageInterview(queryClient, account!, arbitraryApplication, users, stageInterview);

        const newSelfSchedulingLink = {
          scheduling_calendar_email: stage?.schedule_template?.scheduling_calendar_email || account!.scheduling_calendar_email!,
          candidate_scheduling_calendar_email: stage?.schedule_template?.candidate_scheduling_calendar_email || account!.candidate_scheduling_calendar_email,
          schedule_template: {
            candidate_calendar_event_template: {
              id: stage?.schedule_template?.candidate_calendar_event_template?.id,
              title: stage?.schedule_template?.candidate_calendar_event_template?.title || '',
              description: stage?.schedule_template?.candidate_calendar_event_template?.description || '',
              location: stage?.schedule_template?.candidate_calendar_event_template?.location,
              additional_attendees: stage?.schedule_template?.candidate_calendar_event_template?.additional_attendees,
              additional_optional_attendees: stage?.schedule_template?.candidate_calendar_event_template?.additional_optional_attendees,
            },
            business_hours: businessHours,
            allowed_times: [{ start_time: '', end_time: '' }],
            scheduling_interval_minutes: stage?.schedule_template?.scheduling_interval_minutes || 15,
            onsite: stage?.schedule_template?.onsite || false,
            mark_interviewer_events_as_private: stage?.schedule_template?.mark_interviewer_events_as_private || false,
            mark_candidate_events_as_private: stage?.schedule_template?.mark_candidate_events_as_private || false,
            video_conferencing_enabled: stage?.schedule_template?.video_conferencing_enabled || false,
            zoom_host_filters: stage?.schedule_template?.zoom_host_filters || createEmptyZoomHostFilter(),
            create_hiring_channel: stage?.schedule_template?.create_hiring_channel || false,
            confirmation_email_template: stage?.schedule_template?.confirmation_email_template && {
              id: stage?.schedule_template?.confirmation_email_template.id,
              name: stage?.schedule_template?.confirmation_email_template.name,
              subject: stage?.schedule_template?.confirmation_email_template.subject,
              sender_name: stage?.schedule_template?.confirmation_email_template.sender_name,
              sender_email: stage?.schedule_template?.confirmation_email_template.sender_email,
              cc_emails: stage?.schedule_template?.confirmation_email_template.cc_emails,
              bcc_emails: stage?.schedule_template?.confirmation_email_template.bcc_emails,
              body: stage?.schedule_template?.confirmation_email_template.body,
              attachments: stage?.schedule_template?.confirmation_email_template.attachments,
            },
            self_scheduling_advanced_notice_hours: stage?.schedule_template?.self_scheduling_advanced_notice_hours || 24,
            self_scheduling_reschedule_enabled: stage?.schedule_template?.self_scheduling_reschedule_enabled || false,
            self_scheduling_reschedule_notice_hours: stage?.schedule_template?.self_scheduling_reschedule_notice_hours,
            self_scheduling_cancel_enabled: stage?.schedule_template?.self_scheduling_cancel_enabled || false,
            self_scheduling_cancel_notice_hours: stage?.schedule_template?.self_scheduling_cancel_notice_hours,
            self_scheduling_email_follow_up_templates: stage?.schedule_template?.self_scheduling_email_follow_up_templates || undefined,
            self_scheduling_request_email_template: stage?.schedule_template?.self_scheduling_request_email_template && {
              id: stage?.schedule_template?.self_scheduling_request_email_template.id,
              name: stage?.schedule_template?.self_scheduling_request_email_template.name,
              subject: stage?.schedule_template?.self_scheduling_request_email_template.subject,
              sender_name: stage?.schedule_template?.self_scheduling_request_email_template.sender_name,
              sender_email: stage?.schedule_template?.self_scheduling_request_email_template.sender_email,
              cc_emails: stage?.schedule_template?.self_scheduling_request_email_template.cc_emails,
              bcc_emails: stage?.schedule_template?.self_scheduling_request_email_template.bcc_emails,
              body: stage?.schedule_template?.self_scheduling_request_email_template.body,
              attachments: stage?.schedule_template?.self_scheduling_request_email_template.attachments,
            },
            // TODO: add room_filters when we're ready to add them
          },
          stage_interview: {
            id: stageInterview?.id,
            feedback_form_id: feedbackFormId,
            interview_template: {
              name: 'Inline Interview Template',
              duration_minutes: duration,
              live_coding_enabled: liveCodingEnabled,
              candidate_facing_name: candidateFacingName,
              candidate_facing_details: candidateFacingDetails,
              interviewer_templates: interviewerTemplates,
            },
          },
        };

        if (isBulk) {
          setSelfSchedulingLink({
            application_ids: applicationIDs,
            ...newSelfSchedulingLink,
          });
        } else {
          setSelfSchedulingLink({
            application_id: applicationIDs[0],
            ...newSelfSchedulingLink,
          });
        }
      })();
    }
  }, [Boolean(!isLoading && !selfSchedulingLinkHasApplication(selfSchedulingLink)), users]);

  const canRequestSelfScheduling = stage?.schedule_template && applications && applications.every((app) => ([
    'availability_requested',
    'cancelled',
    'ready_to_request_availability',
    'ready_to_schedule',
    'scheduled',
    'scheduling_link_sent',
  ].includes(app.scheduling_status)));

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);
  const [isEmailSettingsWarningModalOpen, setIsEmailSettingsWarningModalOpen] = useState(!canSendEmail);

  if (isBulk && applicationIDs.length === 0) {
    return <Redirect to={correctPath('/app/candidates')} />;
  }

  if (isLoading || !arbitraryApplication || (canRequestSelfScheduling && !selfSchedulingLinkHasApplication(selfSchedulingLink) && !isError)) {
    return <LoadingSpinner />;
  }

  const errorMessages: Record<string, ReactNode> = {
    unschedulable: (
      <span>
        You have not enabled scheduling for candidates in the {stage?.name} stage.&nbsp;
        <Link to={correctPath(`/app/jobs/${arbitraryApplication!.job_id}/stages/${arbitraryApplication!.current_stage_id}`)}>
          Enable scheduling here.
        </Link>
      </span>
    ),
    inactive: 'You cannot schedule an inactive candidate.',
  };

  return (
    <Breadcrumb
      data={{
        title: isBulk ? 'Bulk Self-Scheduling' : 'Self-Scheduling',
        pathname: isBulk ? correctPath('/app/candidates/bulk-self-schedule') : correctPath(`/app/candidates/${arbitraryApplication!.id}/self-schedule`),
        search: isBulk ? `?application=${applicationIDs.join('&application=')}` : undefined,
      }}
    >
      {canRequestSelfScheduling && !isError ? (
        <Section
          className="candidate-self-schedule-container"
          title={
            <span>
              Send{' '}
              {isBulk ?
                <Infotip
                  id="bulk-self-scheduling-names"
                  position="bottom"
                  showIcon={false}
                  text={`${applicationIDs.length} ${pluralize('candidate', applicationIDs.length)}`}
                  tooltipText={<>{applications!.map((app) => (<>{app.candidate.name}<br /></>))}</>}
                /> :
                arbitraryApplication.candidate.name
              }
              {' '}a link to schedule {stage?.name}
            </span>
          }
        >
          <EmailSettingsWarningModal
            isOpen={isEmailSettingsWarningModalOpen}
            onToggle={() => setIsEmailSettingsWarningModalOpen(false)}
            type={EmailTemplateType.SelfSchedulingRequestEmail}
          />
          <StepProgressBar
            completedStep={completedStep}
            // We don't want them to be able to click back after it's already
            // been created.
            isDisabled={completedStep === Step.SendLink || completedStep === Step.BulkResults}
            steps={[{
              label: 'Preferences',
              location: pathForSelfScheduling(isBulk, applicationIDs, 'preferences'),
            }, {
              label: 'Events & Emails',
              location: pathForSelfScheduling(isBulk, applicationIDs, 'events-and-emails'),
            }, {
              label: 'Review',
              location: pathForSelfScheduling(isBulk, applicationIDs, 'review'),
            }, !selfSchedulingLink.schedule_template.self_scheduling_request_email_template && completedStep >= Step.Preferences && {
              label: 'Send Link',
              location: pathForSelfScheduling(isBulk, applicationIDs, 'send'),
            }].filter((step): step is { label: string; location: string } => Boolean(step))}
          />
          <div className="candidate-self-schedule-steps-container">
            <Switch>
              <Redirect exact from={correctPath('/app/candidates/:id/self-schedule')} to={correctPath('/app/candidates/:id/self-schedule/preferences')} />
              <Redirect
                exact
                from={correctPath('/app/candidates/bulk-self-schedule')}
                to={{
                  pathname: correctPath('/app/candidates/bulk-self-schedule/preferences'),
                  search: `?application=${applicationIDs.join('&application=')}`,
                }}
              />
              <Route component={PreferencesStep} path={[correctPath('/app/candidates/:id/self-schedule/preferences'), correctPath('/app/candidates/bulk-self-schedule/preferences')]} />
              <Route component={EventsAndEmailsStep} path={[correctPath('/app/candidates/:id/self-schedule/events-and-emails'), correctPath('/app/candidates/bulk-self-schedule/events-and-emails')]} />
              <Route component={ReviewStep} path={[correctPath('/app/candidates/:id/self-schedule/review'), correctPath('/app/candidates/bulk-self-schedule/review')]} />
              <Route component={SendLinkStep} path={[correctPath('/app/candidates/:id/self-schedule/send'), correctPath('/app/candidates/bulk-self-schedule/send')]} />
              <Route component={BulkResultsStep} path={correctPath('/app/candidates/bulk-self-schedule/results')} />
            </Switch>
          </div>
        </Section>
      ) : (
        <>
          {isBulk ?
            <>
              {Object.keys(errorMessages).map((invalidStatus) => (
                <Fragment key={invalidStatus}>
                  {applications && applications.some((app) => app.scheduling_status === invalidStatus) &&
                    <Flash
                      message={<>
                        {errorMessages[invalidStatus]}
                        {/* Don't show the list of candidates with an invalid status for the unschedulable status, since that would just show all selected candidates */}
                        {invalidStatus !== 'unschedulable' &&
                          <ul className="self-scheduling-invalid-app-list">
                            {applications.filter((app) => app.scheduling_status === invalidStatus).map((app) => (
                              <li key={app.id}>
                                <Link to={correctPath(`/app/candidates/${app.id}`)}>
                                  {app.candidate.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        }
                      </>}
                      showFlash
                      type="danger"
                    />}
                </Fragment>
              ))}
            </>
            :
            <Flash
              message={errorMessages[arbitraryApplication!.scheduling_status]}
              showFlash={Boolean(errorMessages[arbitraryApplication!.scheduling_status])}
              type="danger"
            />
          }
          <Flash
            message={
              <>
                This stage doesn&apos;t have any interviews configured. You can add interviews {constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: arbitraryApplication?.job.ats_id }) ? <OutboundLink href={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: arbitraryApplication?.job.ats_id })} label="ATS Interview Plan">here</OutboundLink> : <Link to={correctPath(`/app/jobs/${arbitraryApplication?.job_id}/stages/${arbitraryApplication?.current_stage_id}/interviews`)}>here</Link>}.
              </>
            }
            showFlash={!stage?.stage_interviews}
            type="danger"
          />
        </>
      )}
    </Breadcrumb>
  );
};
