import { useEffect, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import TokenInput from 'components/library/inputs/TokenInput';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { slateValueToText } from 'libraries/editor/slate-value-to-text';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';
import { useTokens } from 'hooks/queries/tokens';
import { useSlateEditor } from 'hooks/use-slate-editor';
import { StyledContainer } from './styles';

import type { ChangeEvent } from 'react';

const CompanyInternalCalendarEventsSection = () => {
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);

  const [interviewerEventTitleSlateEditor, interviewerEventTitleSlateValue, setInterviewerEventTitleSlateValue, setInterviewerEventTitle] = useSlateEditor(account!.interviewer_event_title, true);
  const [usesCandidateEventLocation, setUsesCandidateEventLocation] = useState(account!.interviewer_event_uses_candidate_location);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'interviewer_event_description',
  });

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setInterviewerEventTitle(account!.interviewer_event_title);
    setUsesCandidateEventLocation(account!.interviewer_event_uses_candidate_location);
  }, [account!.interviewer_event_title, account!.interviewer_event_uses_candidate_location]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setInterviewerEventTitle(account!.interviewer_event_title);
    setUsesCandidateEventLocation(account!.interviewer_event_uses_candidate_location);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account!.id,
        payload: {
          interviewer_event_title: slateValueToText(interviewerEventTitleSlateValue),
          interviewer_event_uses_candidate_location: usesCandidateEventLocation,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="internal-calendar-event-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Internal calendar events"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <StyledContainer>
        {tokens && (
          <TokenInput
            editor={interviewerEventTitleSlateEditor}
            helperText="This will be used for the titles of the internal calendar events that are sent to the interviewers."
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            isRequired
            label="Interviewer calendar title"
            pendingPreviewMessage="This token will be filled in when we create the schedule."
            setValue={setInterviewerEventTitleSlateValue}
            tokens={tokens}
            type="interviewer_event_description"
            value={interviewerEventTitleSlateValue}
          />
        )}
        <CheckboxInput
          helperText="Recommended for teams that often conduct offsite interviews."
          isChecked={usesCandidateEventLocation}
          isDisabled={!isEditing || updateAccountMutation.isLoading}
          label="Copy candidate event locations to interviewer calendar events."
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUsesCandidateEventLocation(e.target.checked)}
        />
      </StyledContainer>
    </Section>
  );
};

export default CompanyInternalCalendarEventsSection;
